import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {
    ADAdmin,
    ADDomain,
    ADGroup,
    ADUser,
    ADUserPool,
    ADUserPoolRelationship,
    ADUserServiceExternal,
    Customer,
    CustomerGroup,
    DesktopServer,
    DesktopServerIdWithVersion,
    DesktopServerPool,
    LinuxServer,
    ManagerUser,
    ManagerUserIdWithLastLogin,
    Project,
    RemoteApp,
    RemoteAppRelationship,
    Role,
    RoleIdCustomerGroupIdManagerUserId,
    RoleIdSubProjectIdManagerUserId,
    SoftwareCompany,
    SubProject
} from '../../models';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class R2CloudAdminService implements OnDestroy {
    private _managerUsers$ = new BehaviorSubject<ManagerUser[]>([]);
    private _managerUsers: ManagerUser[] = [];
    private _myAdUsers: ADUser[] = [];
    private _myAdUsers$ = new BehaviorSubject<ADUser[]>([]);
    private _adAdmins$ = new BehaviorSubject<ADAdmin[]>([]);
    private _adAdmins: ADAdmin[] = [];
    private _softwareCompanies: SoftwareCompany[] = [];
    private _softwareCompanies$ = new BehaviorSubject<SoftwareCompany[]>([]);
    private _managerUserIdsWithLastLogin$ = new BehaviorSubject<ManagerUserIdWithLastLogin[]>([]);
    private _managerUserIdsWithLastLogin: ManagerUserIdWithLastLogin[] = [];
    private _managerUsersAdministrators$ = new BehaviorSubject<ManagerUser[]>([]);
    private _managerUsersAdministrators: ManagerUser[] = [];
    private _managerUsersCustomerGroup$ = new BehaviorSubject<ManagerUser[]>([]);
    private _managerUsersCustomerGroup: ManagerUser[] = [];
    private _roleSubProjectManagerUsers$ = new BehaviorSubject<RoleIdSubProjectIdManagerUserId[]>([]);
    private _roleSubProjectManagerUsers: RoleIdSubProjectIdManagerUserId[] = [];
    private _roleCustomerGroupManagerUsers$ = new BehaviorSubject<RoleIdCustomerGroupIdManagerUserId[]>([]);
    private _roleCustomerGroupManagerUsers: RoleIdCustomerGroupIdManagerUserId[] = [];
    private _roles$ = new BehaviorSubject<Role[]>([]);
    private _roles: Role[] = [];
    private _projects$ = new BehaviorSubject<Project[]>([]);
    private _projects: Project[] = [];
    private _customers$ = new BehaviorSubject<Customer[]>([]);
    private _customers: Customer[] = [];
    private _externalCustomers$ = new BehaviorSubject<Customer[]>([]);
    private _externalCustomers: Customer[] = [];
    private _externalCustomerGroups$ = new BehaviorSubject<CustomerGroup[]>([]);
    private _externalCustomerGroups: CustomerGroup[] = [];
    private _subProject$ = new BehaviorSubject<SubProject>(null);
    private _subProject: SubProject;
    private _project$ = new BehaviorSubject<Project>(null);
    private _project: Project;
    private _adDomains$ = new BehaviorSubject<ADDomain[]>([]);
    private _adDomains: ADDomain[] = [];
    private _adGroups$ = new BehaviorSubject<ADGroup[]>([]);
    private _adGroups: ADGroup[] = [];
    private _adUsers$ = new BehaviorSubject<ADUser[]>([]);
    private _adUsers: ADUser[] = [];
    private _adUsersService$ = new BehaviorSubject<ADUser[]>([]);
    private _adUsersService: ADUser[] = [];
    private _adUsersPool$ = new BehaviorSubject<ADUserPool[]>([]);
    private _adUsersPool: ADUserPool[] = [];
    private _adUsersPoolService$ = new BehaviorSubject<ADUserPool[]>([]);
    private _adUsersPoolService: ADUserPool[] = [];
    private _adUsersPoolRelationship$ = new BehaviorSubject<ADUserPoolRelationship[]>([]);
    private _adUsersPoolRelationship: ADUserPoolRelationship[] = [];
    private _adUserServiceExternals$ = new BehaviorSubject<ADUserServiceExternal[]>([]);
    private _adUserServiceExternals: ADUserServiceExternal[] = [];
    private _desktopServers$ = new BehaviorSubject<DesktopServer[]>([]);
    private _desktopServers: DesktopServer[] = [];

    private _desktopServerIdWithVersions$ = new BehaviorSubject<DesktopServerIdWithVersion[]>([]);
    private _desktopServerIdWithVersions: DesktopServerIdWithVersion[] = [];


    private _linuxServers$ = new BehaviorSubject<LinuxServer[]>([]);
    private _linuxServers: LinuxServer[] = [];
    private _remoteApps$ = new BehaviorSubject<RemoteApp[]>([]);
    private _remoteApps: RemoteApp[] = [];
    private _unload$ = new Subject<void>();


    private _remoteAppRelationships$ = new BehaviorSubject<RemoteAppRelationship[]>([]);
    private _remoteAppRelationships: RemoteAppRelationship[] = [];

    private _desktopServerPools$ = new BehaviorSubject<DesktopServerPool[]>([]);
    private _desktopServerPools: DesktopServerPool[] = [];

    private _desktopServersOfPool$ = new BehaviorSubject<DesktopServer[]>([]);
    private _desktopServersOfPool: DesktopServer[] = [];


    constructor() {
    }

    ngOnDestroy(): void {
        this._managerUsers$.complete();
        this._myAdUsers$.complete();
        this._adAdmins$.complete();
        this._softwareCompanies$.complete();
        this._managerUserIdsWithLastLogin$.complete();
        this._managerUsersAdministrators$.complete();
        this._managerUsersCustomerGroup$.complete();
        this._roleSubProjectManagerUsers$.complete();
        this._roleCustomerGroupManagerUsers$.complete();
        this._projects$.complete();
        this._customers$.complete();
        this._externalCustomers$.complete();
        this._externalCustomerGroups$.complete();
        this._subProject$.complete();
        this._project$.complete();
        this._adDomains$.complete();
        this._adGroups$.complete();
        this._adUsers$.complete();
        this._adUsersService$.complete();
        this._adUserServiceExternals$.complete();
        this._desktopServers$.complete();
        this._desktopServerIdWithVersions$.complete();
        this._linuxServers$.complete();
        this._remoteApps$.complete();
        this._remoteAppRelationships$.complete();
        this._desktopServerPools$.complete();
        this._desktopServersOfPool$.complete();
        this._unload$.complete();
    }

    public unload(): void {
        this.managerUsers = [];
        this.myAdUsers = [];
        this.adAdmins = [];
        this.managerUserIdsWithLastLogin = [];
        this.managerUsersAdministrator = [];
        this.managerUsersCustomerGroup = [];
        this.roleSubProjectManagerUsers = [];
        this.roleCustomerGroupManagerUsers = [];
        this.projects = [];
        this.customers = [];
        this.subProject = null;
        this.project = null;
        this.adDomains = [];
        this.adGroups = [];
        this.adUsers = [];
        this.adUsersService = [];
        this.desktopServers = [];
        this.linuxServers = [];
        this.remoteApps = [];
        this._unload$.next();
    }

    get whenUnload$(): Observable<void> {
        return this._unload$;
    }


    get managerUsers$(): Observable<ManagerUser[]> {
        return this._managerUsers$.pipe(filter((o) => !!o));
    }

    get managerUsers(): ManagerUser[] {
        return this._managerUsers;
    }

    set managerUsers(managerUsers: ManagerUser[]) {
        this._managerUsers = managerUsers;
        this._managerUsers$.next(managerUsers);
    }

    public addManagerUser(managerUser: ManagerUser): void {
        const managerUsers = this._managerUsers$.value;
        managerUsers.push(managerUser);
        this.managerUsers = managerUsers;
    }

    public addOrReplaceManagerUser(managerUser: ManagerUser): void {
        const managerUsers = this._managerUsers$.value;
        const index = managerUsers.findIndex((o) => o.id === managerUser.id);
        if (index < 0) {
            managerUsers.push(managerUser);
        } else {
            managerUsers[index] = managerUser;
        }
        this.managerUsers = managerUsers;
    }


    public isExternalManagerUser(managerUser: ManagerUser): boolean {
        const index = this._customers.findIndex((y) => y.id === managerUser.customerId);
        return index < 0;

    }

    public isExternalManagerUserId(managerUserId: number): boolean {
        return !this._managerUsers.some(o => o.id === managerUserId);
    }

    public amountOfExternalManagerUser(managerUsers: ManagerUser[]): number {
        let count = 0;
        for (const managerUser of managerUsers) {
            if (this.isExternalManagerUser(managerUser)) {
                count++;
            }
        }
        return count;
    }

    public amountOfInternalManagerUser(managerUsers: ManagerUser[]): number {
        let count = 0;
        for (const managerUser of managerUsers) {
            if (!this.isExternalManagerUser(managerUser)) {
                count++;
            }
        }
        return count;
    }


    public replaceManagerUser(managerUser: ManagerUser): void {
        const managerUsers = this._managerUsers$.value;
        const index = managerUsers.findIndex((o) => o.id === managerUser.id);
        if (index > -1) {
            managerUsers[index] = managerUser;
            this.managerUsers = managerUsers;
        }
    }


    public removeManagerUser(managerUserId: number): void {
        const managerUsers = this._managerUsers$.value;
        const index = managerUsers.findIndex((o) => o.id === managerUserId);
        if (index > -1) {
            managerUsers.splice(index, 1);
            this.managerUsers = managerUsers;
        }
    }

    getManagerUser(managerUserId: number): ManagerUser {
        if (this.managerUsers) {
            return this.managerUsers.find((managerUser) => {
                return managerUser.id === managerUserId;
            });
        }
        return null;
    }

    get myAdUsers$(): Observable<ADUser[]> {
        return this._myAdUsers$.pipe(filter((o) => !!o));
    }

    get myAdUsers(): ADUser[] {
        return this._myAdUsers;
    }

    set myAdUsers(adUsers: ADUser[]) {
        this._myAdUsers = adUsers;
        this._myAdUsers$.next(adUsers);
    }


    get adAdmins$(): Observable<ADAdmin[]> {
        return this._adAdmins$.pipe(filter((o) => !!o));
    }

    get adAdmins(): ADAdmin[] {
        return this._adAdmins;
    }

    set adAdmins(adAdmins: ADAdmin[]) {
        this._adAdmins = adAdmins;
        this._adAdmins$.next(adAdmins);
    }

    public addADAdmin(adAdmin: ADAdmin): void {
        const adAdmins = this._adAdmins$.value;
        adAdmins.push(adAdmin);
        this.adAdmins = adAdmins;
    }

    public addOrReplaceADAdmin(adAdmin: ADAdmin): void {
        const adAdmins = this._adAdmins$.value;
        const index = adAdmins.findIndex((o) => o.id === adAdmin.id);
        if (index < 0) {
            adAdmins.push(adAdmin);
        } else {
            adAdmins[index] = adAdmin;
        }
        this.adAdmins = adAdmins;
    }


    public replaceADAdmin(adAdmin: ADAdmin): void {
        const adAdmins = this._adAdmins$.value;
        const index = adAdmins.findIndex((o) => o.id === adAdmin.id);
        if (index > -1) {
            adAdmins[index] = adAdmin;
            this.adAdmins = adAdmins;
        }
    }


    public removeADAdmin(adAdminId: number): void {
        const adAdmins = this._adAdmins$.value;
        const index = adAdmins.findIndex((o) => o.id === adAdminId);
        if (index > -1) {
            adAdmins.splice(index, 1);
            this.adAdmins = adAdmins;
        }
    }

    getADAdmin(adAdminId: number): ADAdmin {
        if (this.adAdmins) {
            return this.adAdmins.find((adAdmin) => {
                return adAdmin.id === adAdminId;
            });
        }
        return null;
    }


    get softwareCompanies$(): Observable<SoftwareCompany[]> {
        return this._softwareCompanies$.pipe(filter((o) => !!o));
    }

    get softwareCompanies(): SoftwareCompany[] {
        return this._softwareCompanies;
    }

    set softwareCompanies(softwareCompanies: SoftwareCompany[]) {
        this._softwareCompanies = softwareCompanies;
        this._softwareCompanies$.next(softwareCompanies);
    }

    public addSoftwareCompany(softwareCompany: SoftwareCompany): void {
        const softwareCompanies = this._softwareCompanies$.value;
        softwareCompanies.push(softwareCompany);
        this.softwareCompanies = softwareCompanies;
    }

    public addOrReplaceSoftwareCompany(softwareCompany: SoftwareCompany): void {
        const softwareCompanies = this._softwareCompanies$.value;
        const index = softwareCompanies.findIndex((o) => o.id === softwareCompany.id);
        if (index < 0) {
            softwareCompanies.push(softwareCompany);
        } else {
            softwareCompanies[index] = softwareCompany;
        }
        this.softwareCompanies = softwareCompanies;
    }


    public replaceSoftwareCompany(softwareCompany: SoftwareCompany): void {
        const softwareCompanies = this._softwareCompanies$.value;
        const index = softwareCompanies.findIndex((o) => o.id === softwareCompany.id);
        if (index > -1) {
            softwareCompanies[index] = softwareCompany;
            this.softwareCompanies = softwareCompanies;
        }
    }


    public removeSoftwareCompany(softwareCompanyId: number): void {
        const softwareCompanies = this._softwareCompanies$.value;
        const index = softwareCompanies.findIndex((o) => o.id === softwareCompanyId);
        if (index > -1) {
            softwareCompanies.splice(index, 1);
            this.softwareCompanies = softwareCompanies;
        }
    }

    getSoftwareCompany(softwareCompanyId: number): SoftwareCompany {
        if (this.softwareCompanies) {
            return this.softwareCompanies.find((softwareCompany) => {
                return softwareCompany.id === softwareCompanyId;
            });
        }
        return null;
    }


    checkManagerUserIdHasADAdminOfADDomainAndNoPending(managerUserId: number, adDomainId: number): boolean {
        const adUserList: ADUser[] = _.unionBy(this.myAdUsers, this.adUsers, 'id')
            .filter(
                (o) => o.managerUserId === managerUserId && o.adDomainId === adDomainId && o.deleting === false && o.changing === false
            );
        for (const adUser of adUserList) {
            const index = this.adAdmins.findIndex((o) => o.adUserId === adUser.id && o.adDomainId === adDomainId && o.deleting === false && o.changing === false);
            return index > -1;
        }
        return false;
    }

    getADAdminIfADUserIdHasADAdmin(aDUserId: number): ADAdmin {
        if (this.adAdmins) {
            const index = this.adAdmins.findIndex((o) => o.adUserId === aDUserId);
            if (index > -1) {
                return this.adAdmins[index];
            }
        }
        return null;
    }

    get managerUserIdsWithLastLogin$(): Observable<ManagerUserIdWithLastLogin[]> {
        return this._managerUserIdsWithLastLogin$.pipe(filter((o) => !!o));
    }

    get managerUserIdsWithLastLogin(): ManagerUserIdWithLastLogin[] {
        return this._managerUserIdsWithLastLogin;
    }

    set managerUserIdsWithLastLogin(managerUserIdsWithLastLogin: ManagerUserIdWithLastLogin[]) {
        this._managerUserIdsWithLastLogin = managerUserIdsWithLastLogin;
        this._managerUserIdsWithLastLogin$.next(managerUserIdsWithLastLogin);
    }

    public addManagerUserIdWithLastLogin(managerUserIdWithLastLogin: ManagerUserIdWithLastLogin): void {
        const managerUserIdsWithLastLogin = this._managerUserIdsWithLastLogin$.value;
        managerUserIdsWithLastLogin.push(managerUserIdWithLastLogin);
        this.managerUserIdsWithLastLogin = managerUserIdsWithLastLogin;
    }

    public addOrReplaceManagerUserIdWithLastLogin(managerUserIdWithLastLogin: ManagerUserIdWithLastLogin): void {
        const managerUserIdsWithLastLogin = this._managerUserIdsWithLastLogin$.value;
        const index = managerUserIdsWithLastLogin.findIndex((o) => o.id === managerUserIdWithLastLogin.id);
        if (index < 0) {
            managerUserIdsWithLastLogin.push(managerUserIdWithLastLogin);
        } else {
            managerUserIdsWithLastLogin[index] = managerUserIdWithLastLogin;
        }
        this.managerUserIdsWithLastLogin = managerUserIdsWithLastLogin;
    }


    public replaceManagerUserIdWithLastLogin(managerUserIdWithLastLogin: ManagerUserIdWithLastLogin): void {
        const managerUserIdsWithLastLogin = this._managerUserIdsWithLastLogin$.value;
        const index = managerUserIdsWithLastLogin.findIndex((o) => o.id === managerUserIdWithLastLogin.id);
        if (index > -1) {
            managerUserIdsWithLastLogin[index] = managerUserIdWithLastLogin;
            this.managerUserIdsWithLastLogin = managerUserIdsWithLastLogin;
        }
    }


    public removeManagerUserIdWithLastLogin(id: number): void {
        const managerUserIdsWithLastLogin = this._managerUserIdsWithLastLogin$.value;
        const index = managerUserIdsWithLastLogin.findIndex((o) => o.id === id);
        if (index > -1) {
            managerUserIdsWithLastLogin.splice(index, 1);
            this.managerUserIdsWithLastLogin = managerUserIdsWithLastLogin;
        }
    }

    getManagerUserIdWithLastLoginByManagerUserId(managerUserId: number): ManagerUserIdWithLastLogin {
        if (this.managerUserIdsWithLastLogin) {
            const index = this.managerUserIdsWithLastLogin.findIndex((o) => o.managerUserId === managerUserId);
            if (index > -1) {
                return this.managerUserIdsWithLastLogin[index];
            }
        }
        return null;
    }

    get managerUsersAdministrator$(): Observable<ManagerUser[]> {
        return this._managerUsersAdministrators$.pipe(filter((o) => !!o));
    }

    get managerUsersAdministrator(): ManagerUser[] {
        return this._managerUsersAdministrators;
    }

    set managerUsersAdministrator(managerUsersAdministrator: ManagerUser[]) {
        this._managerUsersAdministrators = managerUsersAdministrator;
        this._managerUsersAdministrators$.next(managerUsersAdministrator);
    }

    public addManagerUserAdministrator(managerUserAdministrator: ManagerUser): void {
        const managerUsersAdministrator = this._managerUsersAdministrators$.value;
        const index = managerUsersAdministrator.findIndex((o) => o.id === managerUserAdministrator.id);
        if (index > -1) {
            this.replaceManagerUserAdministrator(managerUserAdministrator);
        } else {
            managerUsersAdministrator.push(managerUserAdministrator);
            this.managerUsersAdministrator = managerUsersAdministrator;
        }
    }

    public replaceManagerUserAdministrator(managerUserAdministrator: ManagerUser): void {
        const managerUsersAdministrator = this._managerUsersAdministrators$.value;
        const index = managerUsersAdministrator.findIndex((o) => o.id === managerUserAdministrator.id);
        if (index > -1) {
            managerUsersAdministrator[index] = managerUserAdministrator;
            this.managerUsersAdministrator = managerUsersAdministrator;
        }
    }

    getManagerUserAdministrator(managerUserAdministratorId: number): ManagerUser {
        if (this.managerUsersAdministrator) {
            return this.managerUsersAdministrator.find((managerUser) => {
                return managerUser.id === managerUserAdministratorId;
            });
        }
        return null;
    }


    get managerUsersCustomerGroup$(): Observable<ManagerUser[]> {
        return this._managerUsersCustomerGroup$.pipe(filter((o) => !!o));
    }

    get managerUsersCustomerGroup(): ManagerUser[] {
        return this._managerUsersCustomerGroup;
    }

    set managerUsersCustomerGroup(managerUsersCustomerGroup: ManagerUser[]) {
        this._managerUsersCustomerGroup = managerUsersCustomerGroup;
        this._managerUsersCustomerGroup$.next(managerUsersCustomerGroup);
    }

    public addManagerUserCustomerGroup(managerUserCustomerGroup: ManagerUser): void {
        const managerUsersCustomerGroup = this._managerUsersCustomerGroup$.value;
        managerUsersCustomerGroup.push(managerUserCustomerGroup);
        this.managerUsersCustomerGroup = managerUsersCustomerGroup;
    }

    public replaceManagerUserCustomerGroup(managerUserCustomerGroup: ManagerUser): void {
        const managerUsersCustomerGroup = this._managerUsersCustomerGroup$.value;
        const index = managerUsersCustomerGroup.findIndex((o) => o.id === managerUserCustomerGroup.id);
        if (index > -1) {
            managerUsersCustomerGroup[index] = managerUserCustomerGroup;
            this.managerUsersCustomerGroup = managerUsersCustomerGroup;
        }
    }

    getManagerUserCustomerGroup(managerUserCustomerGroupId: number): ManagerUser {
        if (this.managerUsersCustomerGroup) {
            return this.managerUsersCustomerGroup.find((managerUser) => {
                return managerUser.id === managerUserCustomerGroupId;
            });
        }
        return null;
    }

    getNumberOfManagerUserInCustomer(customerId: number): number {
        if (this.managerUsers) {
            let num = 0;
            for (const managerUser of this.managerUsers) {
                if (managerUser.customerId === customerId) {
                    num++;
                }
            }
            return num;
        }
        return null;
    }


    get roleSubProjectManagerUser$(): Observable<RoleIdSubProjectIdManagerUserId[]> {
        return this._roleSubProjectManagerUsers$.pipe(filter((o) => !!o));
    }

    get roleSubProjectManagerUsers(): RoleIdSubProjectIdManagerUserId[] {
        return this._roleSubProjectManagerUsers;
    }

    set roleSubProjectManagerUsers(roleSubProjectManagerUser: RoleIdSubProjectIdManagerUserId[]) {
        this._roleSubProjectManagerUsers = roleSubProjectManagerUser;
        this._roleSubProjectManagerUsers$.next(roleSubProjectManagerUser);
    }

    public addRoleSubProjectManagerUser(roleSubProjectManagerUser: RoleIdSubProjectIdManagerUserId): void {
        const roleSubProjectManagerUserList = this._roleSubProjectManagerUsers$.value;
        roleSubProjectManagerUserList.push(roleSubProjectManagerUser);
        this.roleSubProjectManagerUsers = roleSubProjectManagerUserList;
    }


    public removeRoleSubProjectManagerUser(obj: RoleIdSubProjectIdManagerUserId): void {
        const roleSubProjectManagerUserList = this._roleSubProjectManagerUsers$.value;
        const index = roleSubProjectManagerUserList.findIndex((o) => o.roleId === obj.roleId && o.subProjectId === obj.subProjectId && o.managerUserId === obj.managerUserId);
        if (index > -1) {
            roleSubProjectManagerUserList.splice(index, 1);
            this.roleSubProjectManagerUsers = roleSubProjectManagerUserList;
        }
    }


    public replaceRoleSubProjectGroupManagerUser(obj: RoleIdSubProjectIdManagerUserId, objNew: RoleIdSubProjectIdManagerUserId): void {
        const roleSubProjectManagerUserList = this._roleSubProjectManagerUsers$.value;
        const index = roleSubProjectManagerUserList.findIndex((o) => o.roleId === obj.roleId && o.subProjectId === obj.subProjectId && o.managerUserId === obj.managerUserId);
        if (index > -1) {
            roleSubProjectManagerUserList[index] = objNew;
            this.roleSubProjectManagerUsers = roleSubProjectManagerUserList;
        }
    }


    get roleCustomerGroupManagerUser$(): Observable<RoleIdCustomerGroupIdManagerUserId[]> {
        return this._roleCustomerGroupManagerUsers$.pipe(filter((o) => !!o));
    }

    get roleCustomerGroupManagerUsers(): RoleIdCustomerGroupIdManagerUserId[] {
        return this._roleCustomerGroupManagerUsers;
    }

    set roleCustomerGroupManagerUsers(roleCustomerGroupManagerUser: RoleIdCustomerGroupIdManagerUserId[]) {
        this._roleCustomerGroupManagerUsers = roleCustomerGroupManagerUser;
        this._roleCustomerGroupManagerUsers$.next(roleCustomerGroupManagerUser);
    }

    public addRoleCustomerGroupManagerUser(roleCustomerGroupManagerUser: RoleIdCustomerGroupIdManagerUserId): void {
        const roleCustomerGroupManagerUserList = this._roleCustomerGroupManagerUsers$.value;
        roleCustomerGroupManagerUserList.push(roleCustomerGroupManagerUser);
        this.roleCustomerGroupManagerUsers = roleCustomerGroupManagerUserList;
    }


    public removeRoleCustomerGroupManagerUser(obj: RoleIdCustomerGroupIdManagerUserId): void {
        const roleCustomerGroupManagerUserList = this._roleCustomerGroupManagerUsers$.value;
        const index = roleCustomerGroupManagerUserList.findIndex(
            o => o.roleId === obj.roleId && o.customerGroupId === obj.customerGroupId &&
                o.managerUserId === obj.managerUserId);
        if (index > -1) {
            roleCustomerGroupManagerUserList.splice(index, 1);
            this.roleCustomerGroupManagerUsers = roleCustomerGroupManagerUserList;
        }
    }


    public replaceRoleCustomerGroupManagerUser(obj: RoleIdCustomerGroupIdManagerUserId, objNew: RoleIdCustomerGroupIdManagerUserId): void {
        const roleCustomerGroupManagerUserList = this._roleCustomerGroupManagerUsers$.value;
        const index = roleCustomerGroupManagerUserList.findIndex(
            o => o.roleId === obj.roleId && o.customerGroupId === obj.customerGroupId && o.managerUserId === obj.managerUserId);
        if (index > -1) {
            roleCustomerGroupManagerUserList[index] = objNew;
            this.roleCustomerGroupManagerUsers = roleCustomerGroupManagerUserList;
        }
    }


    get roles$(): Observable<Role[]> {
        return this._roles$.pipe(filter((o) => !!o));
    }

    get roles(): Role[] {
        return this._roles;
    }

    set roles(roles: Role[]) {
        this._roles = roles;
        this._roles$.next(roles);
    }

    public addRole(role: Role): void {
        const roles = this._roles$.value;
        roles.push(role);
        this.roles = roles;
    }

    getRole(roleId: number): Role {
        if (this.roles) {
            return this.roles.find((role) => {
                return role.id === roleId;
            });
        }
        return null;
    }

    get projects$(): Observable<Project[]> {
        return this._projects$.pipe(filter((o) => !!o));
    }

    get projects(): Project[] {
        return this._projects;
    }

    set projects(projects: Project[]) {
        this._projects = projects;
        this._projects$.next(projects);
    }

    public addProject(project: Project): void {
        const projects = this._projects$.value;
        projects.push(project);
        this.projects = projects;
    }

    public replaceProject(project: Project): void {
        const projects = this._projects$.value;
        const index = projects.findIndex((o) => o.id === project.id);
        if (index > -1) {
            projects[index] = project;
            this.projects = projects;
        }
    }

    public getProject(projectId: number): Project {
        const projects = this._projects$.value;
        let project;
        const index = projects.findIndex((o) => o.id === projectId);
        if (index > -1) {
            project = projects[index];
        }
        return project;
    }


    get customers$(): Observable<Customer[]> {
        return this._customers$.pipe(filter((o) => !!o));
    }

    get customers(): Customer[] {
        return this._customers;
    }

    set customers(customers: Customer[]) {
        this._customers = customers;
        this._customers$.next(customers);
    }

    public addCustomer(customer: Customer): void {
        const customers = this._customers$.value;
        customers.push(customer);
        this.customers = customers;
    }

    getCustomer(customerId: number): Customer {
        if (this.customers) {
            return this.customers.find((customer) => {
                return customer.id === customerId;
            });
        }
        return null;
    }


    public removeCustomer(customerId: number): void {
        const customers = this._customers$.value;
        const index = customers.findIndex((o) => o.id === customerId);
        if (index > -1) {
            customers.splice(index, 1);
            this.customers = customers;
        }
    }


    public replaceCustomer(customer: Customer): void {
        const customers = this._customers$.value;
        const index = customers.findIndex((o) => o.id === customer.id);
        if (index > -1) {
            customers[index] = customer;
            this.customers = customers;
        }
    }


    get externalCustomers$(): Observable<Customer[]> {
        return this._externalCustomers$.pipe(filter((o) => !!o));
    }

    get externalCustomers(): Customer[] {
        return this._externalCustomers;
    }

    set externalCustomers(externalCustomers: Customer[]) {
        this._externalCustomers = externalCustomers;
        this._externalCustomers$.next(externalCustomers);
    }

    public addExternalCustomer(customer: Customer): void {
        const externalCustomers = this._externalCustomers$.value;
        externalCustomers.push(customer);
        this.externalCustomers = externalCustomers;
    }

    public addOrReplaceExternalCustomer(externalCustomer: Customer): void {
        const externalCustomers = this._externalCustomers$.value;
        const index = externalCustomers.findIndex((o) => o.id === externalCustomer.id);
        if (index < 0) {
            externalCustomers.push(externalCustomer);
        } else {
            externalCustomers[index] = externalCustomer;
        }
        this.externalCustomers = externalCustomers;
    }

    getExternalCustomer(customerId: number): Customer {
        if (this.externalCustomers) {
            return this.externalCustomers.find((customer) => {
                return customer.id === customerId;
            });
        }
        return null;
    }

    public replaceExternalCustomer(customer: Customer): void {
        const externalCustomers = this._externalCustomers$.value;
        const index = externalCustomers.findIndex((o) => o.id === customer.id);
        if (index > -1) {
            externalCustomers[index] = customer;
            this.externalCustomers = externalCustomers;
        }
    }


    get externalCustomerGroups$(): Observable<CustomerGroup[]> {
        return this._externalCustomerGroups$.pipe(filter((o) => !!o));
    }

    get externalCustomerGroups(): CustomerGroup[] {
        return this._externalCustomerGroups;
    }

    set externalCustomerGroups(externalCustomerGroups: CustomerGroup[]) {
        this._externalCustomerGroups = externalCustomerGroups;
        this._externalCustomerGroups$.next(externalCustomerGroups);
    }

    public addExternalCustomerGroup(customerGroup: CustomerGroup): void {
        const externalCustomerGroups = this._externalCustomerGroups$.value;
        externalCustomerGroups.push(customerGroup);
        this.externalCustomerGroups = externalCustomerGroups;
    }

    public addOrReplaceExternalCustomerGroup(externalCustomerGroup: CustomerGroup): void {
        const externalCustomerGroups = this._externalCustomerGroups$.value;
        const index = externalCustomerGroups.findIndex((o) => o.id === externalCustomerGroup.id);
        if (index < 0) {
            externalCustomerGroups.push(externalCustomerGroup);
        } else {
            externalCustomerGroups[index] = externalCustomerGroup;
        }
        this.externalCustomerGroups = externalCustomerGroups;
    }

    getExternalCustomerGroup(customerGroupId: number): CustomerGroup {
        if (this.externalCustomerGroups) {
            return this.externalCustomerGroups.find((customerGroup) => {
                return customerGroup.id === customerGroupId;
            });
        }
        return null;
    }

    public replaceExternalCustomerGroup(customerGroup: CustomerGroup): void {
        const externalCustomerGroups = this._externalCustomerGroups$.value;
        const index = externalCustomerGroups.findIndex((o) => o.id === customerGroup.id);
        if (index > -1) {
            externalCustomerGroups[index] = customerGroup;
            this.externalCustomerGroups = externalCustomerGroups;
        }
    }


    get subProject$(): Observable<SubProject> {
        return this._subProject$.pipe(filter((o) => !!o && !!o.id), distinctUntilChanged());
    }

    get subProject(): SubProject {
        return this._subProject;
    }

    set subProject(subProject: SubProject) {
        this._subProject = subProject;
        this._subProject$.next(subProject);
    }


    get project$(): Observable<Project> {
        return this._project$.pipe(filter((o) => !!o && !!o.id), distinctUntilChanged());
    }

    get project(): Project {
        return this._project;
    }

    set project(project: Project) {
        this._project = project;
        this._project$.next(project);
    }


    get adDomains$(): Observable<ADDomain[]> {
        return this._adDomains$.pipe(filter((o) => !!o));
    }

    get adDomains(): ADDomain[] {
        return this._adDomains;
    }

    getAdDomainByDesktopServerId(desktopServerId: number): ADDomain {
        if (this.adDomains) {
            return this.adDomains.find((adDomain) => {
                return adDomain.adServerId === desktopServerId;
            });
        }
        return null;
    }

    getAdDomain(adDomainId: number): ADDomain {
        if (this.adDomains) {
            return this.adDomains.find((adDomain) => {
                return adDomain.id === adDomainId;
            });
        }
        return null;
    }

    set adDomains(adDomains: ADDomain[]) {
        this._adDomains = adDomains;
        this._adDomains$.next(adDomains);
    }

    public addADDomain(adDomain: ADDomain): void {
        const adDomains = this._adDomains$.value;
        adDomains.push(adDomain);
        this.adDomains = adDomains;
    }

    public replaceADDomain(adDomain: ADDomain): void {
        const adDomains = this._adDomains$.value;
        const index = adDomains.findIndex((o) => o.id === adDomain.id);
        if (index > -1) {
            adDomains[index] = adDomain;
            this.adDomains = adDomains;
        }
    }

    get adGroups$(): Observable<ADGroup[]> {
        return this._adGroups$.pipe(filter((o) => !!o));
    }

    get adGroups(): ADGroup[] {
        return this._adGroups;
    }

    getAdGroup(adGroupId: number): ADGroup {
        if (this.adGroups) {
            return this.adGroups.find((adGroup) => {
                return adGroup.id === adGroupId;
            });
        }
        return null;
    }

    getAdGroups(adDomainId: number): ADGroup[] {
        const adGroupsFilter: ADGroup[] = [];
        if (this.adGroups) {
            for (let i = 0; i < this.adGroups.length; i++) {
                if (this.adGroups[i].adDomainId === adDomainId) {
                    adGroupsFilter.push(this.adGroups[i]);
                }
            }
        }
        return adGroupsFilter;
    }

    set adGroups(adGroups: ADGroup[]) {
        this._adGroups = adGroups;
        this._adGroups$.next(adGroups);
    }

    public removeADGroup(adGroupId: number): void {
        const adGroups = this._adGroups$.value;
        const index = adGroups.findIndex((o) => o.id === adGroupId);
        if (index > -1) {
            adGroups.splice(index, 1);
            this.adGroups = adGroups;
        }
    }

    public replaceADGroup(adGroup: ADGroup): void {
        const adGroups = this._adGroups$.value;
        const index = adGroups.findIndex((o) => o.id === adGroup.id);
        if (index > -1) {
            adGroups[index] = adGroup;
            this.adGroups = adGroups;
        }
    }

    public addADGroup(adGroup: ADGroup): void {
        const adGroups = this._adGroups$.value;
        adGroups.push(adGroup);
        this.adGroups = adGroups;
    }

    get adUsers$(): Observable<ADUser[]> {
        return this._adUsers$.pipe(filter((o) => !!o));
    }

    get adUsers(): ADUser[] {
        return this._adUsers;
    }

    getAdUser(adUserId: number): ADUser {
        if (this.adUsers) {
            return this.adUsers.find((adUser) => {
                return adUser.id === adUserId;
            });
        }
        return null;
    }

    public addADUser(adUser: ADUser): void {
        const adUsers = this._adUsers$.value;
        adUsers.push(adUser);
        this.adUsers = adUsers;
    }
    public addOrReplaceADUser(adUser: ADUser): void {
        const adUsers = this._adUsers$.value;
        const index = adUsers.findIndex((o) => o.id === adUser.id);
        if (index < 0) {
            adUsers.push(adUser);
        } else {
            adUsers[index] = adUser;
        }
        this.adUsers = adUsers;
    }

    public replaceADUser(adUser: ADUser): void {
        const adUsers = this._adUsers$.value;
        const index = adUsers.findIndex((o) => o.id === adUser.id);
        if (index > -1) {
            adUsers[index] = adUser;
            this.adUsers = adUsers;
        }
    }

    public removeADUser(adUserId: number): void {
        const adUsers = this._adUsers$.value;
        const index = adUsers.findIndex((o) => o.id === adUserId);
        if (index > -1) {
            adUsers.splice(index, 1);
            this.adUsers = adUsers;
        }
    }

    set adUsers(adUsers: ADUser[]) {
        this._adUsers = adUsers;
        this._adUsers$.next(adUsers);
    }

    get adUsersService$(): Observable<ADUser[]> {
        return this._adUsersService$.pipe(filter((o) => !!o));
    }

    get adUsersService(): ADUser[] {
        // return this._adUsersService;
        return this._adUsersService.filter(x => x?.login);
    }

    set adUsersService(adUsersService: ADUser[]) {
        this._adUsersService = adUsersService;
        this._adUsersService$.next(adUsersService);
    }

    public addADUserService(adUser: ADUser): void {
        const adUsersService = this._adUsersService$.value;
        adUsersService.push(adUser);
        this.adUsersService = adUsersService;
    }

    public replaceADUserService(adUser: ADUser): void {
        const adUsersService = this._adUsersService$.value;
        const index = adUsersService.findIndex((o) => o.id === adUser.id);
        if (index > -1) {
            adUsersService[index] = adUser;
            this.adUsersService = adUsersService;
        }
    }

    public removeADUserService(adUserId: number): void {
        const adUsersService = this._adUsersService$.value;
        const index = adUsersService.findIndex((o) => o.id === adUserId);
        if (index > -1) {
            adUsersService.splice(index, 1);
            this.adUsersService = adUsersService;
        }
    }


    get adUsersPool$(): Observable<ADUserPool[]> {
        return this._adUsersPool$.pipe(filter((o) => !!o));
    }

    get adUsersPool(): ADUserPool[] {
        return this._adUsersPool;
    }

    getAdUserPool(adUserPoolId: number): ADUserPool {
        if (this.adUsersPool) {
            return this.adUsersPool.find((adUserPool) => {
                return adUserPool.id === adUserPoolId;
            });
        }
        return null;
    }

    public addADUserPool(adUserPool: ADUserPool): void {
        const adUsersPool = this._adUsersPool$.value;
        adUsersPool.push(adUserPool);
        this.adUsersPool = adUsersPool;
    }

    public replaceADUserPool(adUserPool: ADUserPool): void {
        const adUsersPool = this._adUsersPool$.value;
        const index = adUsersPool.findIndex((o) => o.id === adUserPool.id);
        if (index > -1) {
            adUsersPool[index] = adUserPool;
            this.adUsersPool = adUsersPool;
        }
    }

    public removeADUserPool(adUserPoolId: number): void {
        const adUsersPool = this._adUsersPool$.value;
        const index = adUsersPool.findIndex((o) => o.id === adUserPoolId);
        if (index > -1) {
            adUsersPool.splice(index, 1);
            this.adUsersPool = adUsersPool;
        }
    }

    set adUsersPool(adUsersPool: ADUserPool[]) {
        this._adUsersPool = adUsersPool;
        this._adUsersPool$.next(adUsersPool);
    }


    get adUsersPoolRelationship$(): Observable<ADUserPoolRelationship[]> {
        return this._adUsersPoolRelationship$.pipe(filter((o) => !!o));
    }

    get adUsersPoolRelationship(): ADUserPoolRelationship[] {
        return this._adUsersPoolRelationship;
    }

    getAdUserPoolRelationship(adUserPoolRelationshipId: number): ADUserPoolRelationship {
        if (this.adUsersPoolRelationship) {
            return this.adUsersPoolRelationship.find((adUserPoolRelationship) => {
                return adUserPoolRelationship.id === adUserPoolRelationshipId;
            });
        }
        return null;
    }

    public addADUserPoolRelationship(adUserPoolRelationship: ADUserPoolRelationship): void {
        const adUsersPoolRelationship = this._adUsersPoolRelationship$.value;
        adUsersPoolRelationship.push(adUserPoolRelationship);
        this.adUsersPoolRelationship = adUsersPoolRelationship;
    }

    public replaceADUserPoolRelationship(adUserPoolRelationship: ADUserPoolRelationship): void {
        const adUsersPoolRelationship = this._adUsersPoolRelationship$.value;
        const index = adUsersPoolRelationship.findIndex((o) => o.id === adUserPoolRelationship.id);
        if (index > -1) {
            adUsersPoolRelationship[index] = adUserPoolRelationship;
            this.adUsersPoolRelationship = adUsersPoolRelationship;
        }
    }

    public removeADUserPoolRelationship(adUserPoolRelationshipId: number): void {
        const adUsersPoolRelationship = this._adUsersPoolRelationship$.value;
        const index = adUsersPoolRelationship.findIndex((o) => o.id === adUserPoolRelationshipId);
        if (index > -1) {
            adUsersPoolRelationship.splice(index, 1);
            this.adUsersPoolRelationship = adUsersPoolRelationship;
        }
    }

    set adUsersPoolRelationship(adUsersPoolRelationship: ADUserPoolRelationship[]) {
        this._adUsersPoolRelationship = adUsersPoolRelationship;
        this._adUsersPoolRelationship$.next(adUsersPoolRelationship);
    }

    public findADUsersPoolRelationshipByADUserPoolService(serviceUser: boolean): ADUserPoolRelationship[] {
        const adUsersPoolRelationship = this._adUsersPoolRelationship$.value;
        return adUsersPoolRelationship.filter((adUserPoolRelationship) => {
            if (serviceUser) {
                return this.getAdUserPoolService(adUserPoolRelationship.adUserPoolId);
            } else {
                return this.getAdUserPool(adUserPoolRelationship.adUserPoolId);
            }
        });
    }


    get adUsersPoolService$(): Observable<ADUserPool[]> {
        return this._adUsersPoolService$.pipe(filter((o) => !!o));
    }

    get adUsersPoolService(): ADUserPool[] {
        return this._adUsersPoolService;
    }

    getAdUserPoolService(adUserPoolServiceId: number): ADUserPool {
        if (this.adUsersPoolService) {
            return this.adUsersPoolService.find((adUserPoolService) => {
                return adUserPoolService.id === adUserPoolServiceId;
            });
        }
        return null;
    }

    public addADUserPoolService(adUserPoolService: ADUserPool): void {
        const adUsersPoolService = this._adUsersPoolService$.value;
        adUsersPoolService.push(adUserPoolService);
        this.adUsersPoolService = adUsersPoolService;
    }

    public replaceADUserPoolService(adUserPoolService: ADUserPool): void {
        const adUsersPoolService = this._adUsersPoolService$.value;
        const index = adUsersPoolService.findIndex((o) => o.id === adUserPoolService.id);
        if (index > -1) {
            adUsersPoolService[index] = adUserPoolService;
            this.adUsersPoolService = adUsersPoolService;
        }
    }

    public removeADUserPoolService(adUserPoolServiceId: number): void {
        const adUsersPoolService = this._adUsersPoolService$.value;
        const index = adUsersPoolService.findIndex((o) => o.id === adUserPoolServiceId);
        if (index > -1) {
            adUsersPoolService.splice(index, 1);
            this.adUsersPoolService = adUsersPoolService;
        }
    }

    set adUsersPoolService(adUsersPoolService: ADUserPool[]) {
        this._adUsersPoolService = adUsersPoolService;
        this._adUsersPoolService$.next(adUsersPoolService);
    }


    get adUserServiceExternals$(): Observable<ADUserServiceExternal[]> {
        return this._adUserServiceExternals$.pipe(filter((o) => !!o));
    }

    get adUserServiceExternals(): ADUserServiceExternal[] {
        return this._adUserServiceExternals;
    }

    set adUserServiceExternals(userServiceExternals: ADUserServiceExternal[]) {
        this._adUserServiceExternals = userServiceExternals;
        this._adUserServiceExternals$.next(userServiceExternals);
    }

    public addADUserServiceExternal(adUserServiceExternal: ADUserServiceExternal): void {
        const adUserServices = this._adUserServiceExternals$.value;
        adUserServices.push(adUserServiceExternal);
        this.adUserServiceExternals = adUserServices;
    }

    public replaceADUserServiceExternal(adUserServiceExternal: ADUserServiceExternal): void {
        const adUserServices = this._adUserServiceExternals$.value;
        const index = adUserServices.findIndex((o) => o.id === adUserServiceExternal.id);
        if (index > -1) {
            adUserServices[index] = adUserServiceExternal;
            this.adUserServiceExternals = adUserServices;
        }
    }

    public removeADUserServiceExternal(adUserServiceExternalId: number): void {
        const adUserServices = this._adUserServiceExternals$.value;
        const index = adUserServices.findIndex((o) => o.id === adUserServiceExternalId);
        if (index > -1) {
            adUserServices.splice(index, 1);
            this.adUserServiceExternals = adUserServices;
        }
    }


    get desktopServerIdWithVersions$(): Observable<DesktopServerIdWithVersion[]> {
        return this._desktopServerIdWithVersions$.pipe(filter((o) => !!o));
    }

    get desktopServerIdWithVersions(): DesktopServerIdWithVersion[] {
        return this._desktopServerIdWithVersions;
    }

    getDesktopServerIdWithVersion(desktopServerId: number): DesktopServerIdWithVersion {
        if (this.desktopServerIdWithVersions) {
            return this.desktopServerIdWithVersions.find((o) => o.desktopServerId === desktopServerId);
        } else {
            return null;
        }
    }

    set desktopServerIdWithVersions(desktopServerIdWithVersions: DesktopServerIdWithVersion[]) {
        this._desktopServerIdWithVersions = desktopServerIdWithVersions;
        this._desktopServerIdWithVersions$.next(desktopServerIdWithVersions);
    }


    get desktopServers$(): Observable<DesktopServer[]> {
        return this._desktopServers$.pipe(filter((o) => !!o));
    }

    get desktopServers(): DesktopServer[] {
        return this._desktopServers;
    }

    getDesktopServer(desktopServerId: number): DesktopServer {
        if (this.desktopServers) {
            return this.desktopServers.find((o) => o.id === desktopServerId);
        } else {
            return null;
        }
    }

    set desktopServers(desktopServers: DesktopServer[]) {
        this._desktopServers = desktopServers;
        this._desktopServers$.next(desktopServers);
    }


    get linuxServers$(): Observable<LinuxServer[]> {
        return this._linuxServers$.pipe(filter((o) => !!o));
    }

    get linuxServers(): LinuxServer[] {
        return this._linuxServers;
    }

    set linuxServers(linuxServers: LinuxServer[]) {
        this._linuxServers = linuxServers;
        this._linuxServers$.next(linuxServers);
    }

    get remoteApps$(): Observable<RemoteApp[]> {
        return this._remoteApps$.pipe(filter((o) => !!o));
    }

    get remoteApps(): RemoteApp[] {
        return this._remoteApps;
    }

    getRemoteApp(remoteAppId: number): RemoteApp {
        if (this.remoteApps) {
            return this.remoteApps.find((remoteApp) => {
                return remoteApp.id === remoteAppId;
            });
        }
        return null;
    }

    set remoteApps(remoteApps: RemoteApp[]) {
        this._remoteApps = remoteApps;
        this._remoteApps$.next(remoteApps);
    }

    public addRemoteApp(remoteApp: RemoteApp): void {
        const remoteApps = this._remoteApps$.value;
        remoteApps.push(remoteApp);
        this.remoteApps = remoteApps;
    }

    public replaceRemoteApp(remoteApp: RemoteApp): void {
        const remoteApps = this._remoteApps$.value;
        const index = remoteApps.findIndex((o) => o.id === remoteApp.id);
        if (index > -1) {
            remoteApps[index] = remoteApp;
            this.remoteApps = remoteApps;
        }
    }

    public removeRemoteApp(remoteAppId: number): void {
        const remoteApps = this._remoteApps$.value;
        const index = remoteApps.findIndex((o) => o.id === remoteAppId);
        if (index > -1) {
            remoteApps.splice(index, 1);
            this.remoteApps = remoteApps;
        }
    }

    public removeRemoteAppByDesktopServerId(desktopServerId: number): void {
        const remoteApps = this._remoteApps$.value;
        const remoteAppsSave: RemoteApp[] = [];
        for (const remoteApp of remoteApps) {
            if (remoteApp.desktopServerId !== desktopServerId) {
                remoteAppsSave.push(remoteApp);
            }
        }
        this.remoteApps = remoteAppsSave;
    }


    get remoteAppRelationships$(): Observable<RemoteAppRelationship[]> {
        return this._remoteAppRelationships$.pipe(filter((o) => !!o));
    }

    get remoteAppRelationships(): RemoteAppRelationship[] {
        return this._remoteAppRelationships;
    }

    getRemoteAppRelationship(remoteAppRelationshipId: number): RemoteAppRelationship {
        if (this.remoteAppRelationships) {
            return this.remoteAppRelationships.find((remoteAppRelationship) => {
                return remoteAppRelationship.id === remoteAppRelationshipId;
            });
        }
        return null;
    }

    getRemoteAppRelationshipsByRemoteAppId(remoteAppId: number): RemoteAppRelationship[] {
        if (this.remoteAppRelationships) {
            return this.remoteAppRelationships.filter((remoteAppRelationship) => {
                return remoteAppRelationship.remoteAppId === remoteAppId;
            });
        }
        return [];
    }

    set remoteAppRelationships(remoteAppRelationships: RemoteAppRelationship[]) {
        this._remoteAppRelationships = remoteAppRelationships;
        this._remoteAppRelationships$.next(remoteAppRelationships);
    }

    public addRemoteAppRelationship(remoteAppRelationship: RemoteAppRelationship): void {
        const remoteAppRelationships = this._remoteAppRelationships$.value;
        remoteAppRelationships.push(remoteAppRelationship);
        this.remoteAppRelationships = remoteAppRelationships;
    }

    public replaceRemoteAppRelationship(remoteAppRelationship: RemoteAppRelationship): void {
        const remoteAppRelationships = this._remoteAppRelationships$.value;
        const index = remoteAppRelationships.findIndex((o) => o.id === remoteAppRelationship.id);
        if (index > -1) {
            remoteAppRelationships[index] = remoteAppRelationship;
            this.remoteAppRelationships = remoteAppRelationships;
        }
    }

    public removeRemoteAppRelationship(remoteAppRelationshipId: number): void {
        const remoteAppRelationships = this._remoteAppRelationships$.value;
        const index = remoteAppRelationships.findIndex((o) => o.id === remoteAppRelationshipId);
        if (index > -1) {
            remoteAppRelationships.splice(index, 1);
            this.remoteAppRelationships = remoteAppRelationships;
        }
    }

    public removeRemoteAppRelationshipByDesktopServerId(desktopServerId: number): void {
        const remoteAppRelationships = this._remoteAppRelationships$.value;
        const remoteAppRelationshipsSave: RemoteAppRelationship[] = [];
        for (const remoteAppRelationship of remoteAppRelationships) {
            if (remoteAppRelationship.desktopServerId !== desktopServerId) {
                remoteAppRelationshipsSave.push(remoteAppRelationship);
            }
        }
        this.remoteAppRelationships = remoteAppRelationshipsSave;
    }


    get desktopServerPools$(): Observable<DesktopServerPool[]> {
        return this._desktopServerPools$.pipe(filter((o) => !!o));
    }

    get desktopServerPools(): DesktopServerPool[] {
        return this._desktopServerPools;
    }

    getDesktopServerPool(desktopServerPoolId: number): DesktopServerPool {
        if (this.desktopServerPools) {
            return this.desktopServerPools.find((desktopServerPool) => {
                return desktopServerPool.id === desktopServerPoolId;
            });
        }
        return null;
    }


    set desktopServerPools(desktopServerPools: DesktopServerPool[]) {
        this._desktopServerPools = desktopServerPools;
        this._desktopServerPools$.next(desktopServerPools);
    }

    public addDesktopServerPool(desktopServerPool: DesktopServerPool): void {
        const desktopServerPools = this._desktopServerPools$.value;
        desktopServerPools.push(desktopServerPool);
        this.desktopServerPools = desktopServerPools;
    }

    public replaceDesktopServerPool(desktopServerPool: DesktopServerPool): void {
        const desktopServerPools = this._desktopServerPools$.value;
        const index = desktopServerPools.findIndex((o) => o.id === desktopServerPool.id);
        if (index > -1) {
            desktopServerPools[index] = desktopServerPool;
            this.desktopServerPools = desktopServerPools;
        }
    }

    public removeDesktopServerPool(desktopServerPoolId: number): void {
        const desktopServerPools = this._desktopServerPools$.value;
        const index = desktopServerPools.findIndex((o) => o.id === desktopServerPoolId);
        if (index > -1) {
            desktopServerPools.splice(index, 1);
            this.desktopServerPools = desktopServerPools;
        }
    }


    get desktopServersOfPool$(): Observable<DesktopServer[]> {
        return this._desktopServersOfPool$.pipe(filter((o) => !!o));
    }

    get desktopServersOfPool(): DesktopServer[] {
        return this._desktopServersOfPool;
    }

    getDesktopServersOfPool(desktopServerOfPoolId: number): DesktopServer[] {
        if (this.desktopServersOfPool) {
            return this.desktopServersOfPool.filter((desktopServerOfPool) => {
                return desktopServerOfPool.id === desktopServerOfPoolId;
            });
        }
        return [];
    }

    set desktopServersOfPool(desktopServerOfPools: DesktopServer[]) {
        this._desktopServersOfPool = desktopServerOfPools;
        this._desktopServersOfPool$.next(desktopServerOfPools);
    }

    public addDesktopServerOfPool(desktopServerOfPool: DesktopServer): void {
        const desktopServerOfPools = this._desktopServersOfPool$.value;
        desktopServerOfPools.push(desktopServerOfPool);
        this.desktopServersOfPool = desktopServerOfPools;
    }

    public replaceDesktopServerOfPool(desktopServerOfPool: DesktopServer): void {
        const desktopServerOfPools = this._desktopServersOfPool$.value;
        const index = desktopServerOfPools.findIndex((o) => o.id === desktopServerOfPool.id);
        if (index > -1) {
            desktopServerOfPools[index] = desktopServerOfPool;
            this.desktopServersOfPool = desktopServerOfPools;
        }
    }

    public removeDesktopServerOfPool(desktopServerOfPoolId: number): void {
        const desktopServerOfPools = this._desktopServersOfPool$.value;
        const index = desktopServerOfPools.findIndex((o) => o.id === desktopServerOfPoolId);
        if (index > -1) {
            desktopServerOfPools.splice(index, 1);
            this.desktopServersOfPool = desktopServerOfPools;
        }
    }

    getDesktopServerOfPoolOrDesktopServers(desktopServerId: number): DesktopServer {
        if (this.desktopServersOfPool) {
            return this.desktopServersOfPool.find((desktopServerOfPool) => {
                return desktopServerOfPool.id === desktopServerId;
            });
        }
        return this.getDesktopServer(desktopServerId);
    }

    getDesktopServersByDesktopServerPoolId(desktopServerPoolId: number): DesktopServer[] {
        const desktopServers = [...this.desktopServersOfPool, ...this.desktopServers];
        return desktopServers.filter((desktopServerOfPool) => {
            return desktopServerOfPool.desktopServerPoolId === desktopServerPoolId;
        });
    }


    public addDesktopServer(desktopServer: DesktopServer): void {
        let desktopServers = this._desktopServers$.value;
        if (!desktopServers) {
            desktopServers = [];
        }
        desktopServers.push(desktopServer);
        this.desktopServers = desktopServers;
    }

    public replaceDesktopServer(desktopServer: DesktopServer): void {
        let desktopServers = this._desktopServers$.value;
        if (!desktopServers) {
            desktopServers = [];
        }
        const index = desktopServers.findIndex((o) => o.id === desktopServer.id);
        if (index > -1) {
            desktopServers[index] = desktopServer;
            this.desktopServers = desktopServers;
        }
    }

    public addOrReplaceDesktopServer(desktopServer: DesktopServer): void {
        let desktopServers = this._desktopServers$.value;
        if (!desktopServers) {
            desktopServers = [];
        }
        const index = desktopServers.findIndex((o) => o.id === desktopServer.id);
        if (index > -1) {
            desktopServers[index] = desktopServer;
            this.desktopServers = desktopServers;
        } else {
            desktopServers.push(desktopServer);
            this.desktopServers = desktopServers;
        }
    }

    public removeDesktopServer(desktopServerId: number): void {
        let desktopServers = this._desktopServers$.value;
        if (!desktopServers) {
            desktopServers = [];
        }
        const index = desktopServers.findIndex((o) => o.id === desktopServerId);
        if (index > -1) {
            desktopServers.splice(index, 1);
            this.desktopServers = desktopServers;
        }
    }


    public addLinuxServer(linuxServer: LinuxServer): void {
        const linuxServers = this._linuxServers$.value;
        linuxServers.push(linuxServer);
        this.linuxServers = linuxServers;
    }

    public replaceLinuxServer(linuxServer: LinuxServer): void {
        const linuxServers = this._linuxServers$.value;
        const index = linuxServers.findIndex((o) => o.id === linuxServer.id);
        if (index > -1) {
            linuxServers[index] = linuxServer;
            this.linuxServers = linuxServers;
        }
    }

    public removeLinuxServer(linuxServerId: number): void {
        const linuxServers = this._linuxServers$.value;
        const index = linuxServers.findIndex((o) => o.id === linuxServerId);
        if (index > -1) {
            linuxServers.splice(index, 1);
            this.linuxServers = linuxServers;
        }
    }
}
