import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable, of} from 'rxjs';
import {
    ChartPoint,
    EmailSubProjectId,
    LoggedUser,
    ManagerUser,
    ManagerUserCustomerGroupData,
    ManagerUserIdWithLastLogin,
    ManagerUserOnline,
    ManagerUserOnlineDisconnectRequest,
    ManagerUserSimple,
    ManagerUserWithSubProject,
    R2cloudStompSession,
    SearchCriteria
} from '../../models';
import {catchError, tap} from 'rxjs/operators';
import {Page} from '../../helpers/Page';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class ManagerUserDaoService extends BaseDao<ManagerUser> {
    private dict = {};

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'manager-users');
    }

    innitManagerUserSimple(email: string): ManagerUserSimple {
        return {
            email: email,
            id: 0,
            active: false,
            comment: '',
            displayName: email,
            imageFileId: null,
            picture: null,
            modified: null,
            optlock: null,
            imageUUID: null
        };
    }

    filter(params?: any): Observable<ManagerUser[]> {
        return this.http.get<ManagerUser[]>(this.url + '/filter', params);
    }

    findByEmail(email: string): Observable<ManagerUser> {
        return this.http.get<ManagerUser>(this.url + '/manager-user-without-acl', {'email': email});
    }

    publicEmailExists(email: string): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/public/email-exists', {'email': email});
    }

    findUnprivilegedByEmailLike(email: string): Observable<ManagerUser[]> {
        return this.http.get<ManagerUser[]>(this.url + '/find-unprivileged-by-email-like', {'email': email});
    }

    getManagerUserSimpleByEmail(email: string): Observable<ManagerUserSimple> {
        if (this.dict[email]) {
            return of(this.dict[email]);
        } else {
            return this.http.get<ManagerUserSimple>(`${this.url}/simple/${email}`).pipe(catchError((e: Response) => {
                if (e.status === 404) {
                    const result: ManagerUserSimple = {
                        email: email,
                        id: 0,
                        active: false,
                        comment: '',
                        displayName: email,
                        imageFileId: null,
                        picture: null,
                        modified: null,
                        optlock: null,
                        imageUUID: null
                    };
                    return of(result);
                }
            }), tap((result) => {
                this.dict[email] = result;
            }));
        }
    }

    /*
    // Esse grafico fica complicado de analisar por um cliente
    getStatistic(subProjectId: Number, from: string, to: string): Observable<ChartPoint[]> {
        return this.http.get<ChartPoint[]>(this.url + '/access-statistics',
            {'subProjectId': subProjectId, 'from': from, 'to': to});
    }
    */

    getAllStatistic(from: string, to: string): Observable<ChartPoint[]> {
        return this.http.get<ChartPoint[]>(this.url + '/all-access-statistics',
            {'from': from, 'to': to});
    }

    getManagerUsersOnline(): Observable<ManagerUserOnline[]> {
        return this.http.get<ManagerUserOnline[]>(this.url + '/manager-users-online');
    }

    getManagerUsersOnlineByCustomerGroupId(customerGroupId: number): Observable<ManagerUserOnline[]> {
        return this.http.get<ManagerUserOnline[]>(this.url + '/manager-users-online-by-customer-group-id/' + customerGroupId);
    }

    reallyDelete(managerUserId: number): Observable<string> {
        return this.http.remove(this.url + '/really-delete/' + managerUserId);
    }

    getAllManagerUsersWithSubProjectIds(): Observable<ManagerUserWithSubProject[]> {
        return this.http.get<ManagerUserWithSubProject[]>(this.url + '/get-all-manager-users-with-sub-project-ids');
    }

    search(searchCriteria: SearchCriteria): Observable<Page<ManagerUser>> {
        return this.http.put<Page<ManagerUser>>(this.url + '/search', searchCriteria);
    }

    disconnectSession(param: ManagerUserOnlineDisconnectRequest): Observable<any> {
        return this.http.post<any>(this.url + '/manager-users-online/disconnect', param);
    }

    findAllByIdList(managerUserIds: number[]): Observable<ManagerUser[]> {
        return this.http.get<ManagerUser[]>(this.url + '/find-all-by-id-list?ids=' + managerUserIds);
    }

    getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(marketplaceOrderId: number): Observable<ManagerUser> {
        return this.http.get<ManagerUser>(this.url + '/get-one-if-it-belongs-to-my-company-or-if-have-an-order-for-my-company/' + marketplaceOrderId);
    }

    logoutByManagerUserId(managerUserId: number): Observable<void> {
        return this.http.get<void>(this.url + '/logout-by-manager-user-id/' + managerUserId);
    }

    changePassword(newPassword: string, forceChangePasswordOnFirstLogin: boolean, managerUserId: number): Observable<any> {
        return this.http.post<any>(this.url + '/change-password/' + managerUserId + '/' + forceChangePasswordOnFirstLogin, newPassword);
    }


    findManagerUserCustomerGroupFromAdUserList(adUserList: string[], desktopServerId: number): Observable<ManagerUserCustomerGroupData[]> {
        return this.http.post<ManagerUserCustomerGroupData[]>(this.url + '/find-manager-user-customer-group-data-from-ad-user-list/' + desktopServerId, adUserList);
    }

    findStompSessionListByCustomerGroupId(
        customerGroupId: number,
        managerUserId: number,
        from: string,
        to: string,
        currentPage: number,
        pageSize: number
    ): Observable<Page<R2cloudStompSession>> {
        return this.http.get<Page<R2cloudStompSession>>(
            this.url + '/stomp-sessions?customerGroupId=' + customerGroupId + '&managerUserId=' + managerUserId + '&from=' + from + '&to=' + to + '&currentPage=' +
            currentPage + '&pageSize=' + pageSize
        );
    }


    managerUsersWithLastLoginInCustomerGroup(customerGroupId: number): Observable<ManagerUserIdWithLastLogin[]> {
        return this.http.get<ManagerUserIdWithLastLogin[]>(this.url + '/manager-users-with-last-login-in-customer-group/?customerGroupId=' + customerGroupId);
    }


    findAllManagerUserOfADUserPoolBySubProjectId(subProjectId: number): Observable<ManagerUser[]> {
        return this.http.get<ManagerUser[]>(this.url + '/find-all-manager-user-of-aduser-pool-by-sub-project-id/?subProjectId=' + subProjectId);
    }

    findAllManagerUserInactiveByCustomerGroupId(customerGroupId: number): Observable<ManagerUser[]> {
        return this.http.get<ManagerUser[]>(this.url + '/find-all-manager-user-inactive-by-customer-group-id/?customerGroupId=' + customerGroupId);
    }

    allLoggedUsersIps(): Observable<LoggedUser[]> {
        return this.http.get<LoggedUser[]>(this.url + '/all-logged-users-ips');
    }

    findAllByEmailAndSubProjectIdList(emails: string[], subProjectId: number): Observable<EmailSubProjectId[]> {
        const emailSubProjectIds: EmailSubProjectId[] = [];
        emails.forEach((email) => {
            emailSubProjectIds.push(
                {
                    email: email,
                    subProjectId: subProjectId,
                    hasRole: null,
                    exists: null,
                    existsInThisSubProject: null
                }
            );
        });
        return this.http.post<EmailSubProjectId[]>(this.url + '/find-all-by-email-and-sub-project-id-list', emailSubProjectIds);
    }

    createAllWithNotExists(managerUsers: ManagerUser[]): Observable<ManagerUser[]> {
        return this.http.post<ManagerUser[]>(this.url + '/create-all-with-not-exists', managerUsers);
    }

}
