import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {environment} from '../../environments/environment';
import {UserAuthorityModule} from './main/user-authority/user-authority.module';
import {CustomerModule} from './main/customer/customer.module';
import {ProjectModule} from './main/project/project.module';
import {DesktopModule} from './main/desktop/desktop.module';
import {LoginModule} from './main/login/login.module';
import {HomeModule} from './home/home.module';
import {ManagerUserModule} from './main/manager-user/manager-user.module';
import {ProfileModule} from './main/profile/profile.module';
import {AdDomainModule} from './main/ad-domain/ad-domain.module';
import {DesktopServerModule} from './main/desktop-server/desktop-server.module';
import {AdGroupModule} from './main/ad-group/ad-group.module';
import {RemoteAppModule} from './main/remote-app/remote-app.module';
import {AdUserModule} from './main/ad-user/ad-user.module';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {ManagerUserAndAdUserModule} from './main/manager-user-and-ad-user/manager-user-and-ad-user.module';
import {SubProjectModule} from './main/r2-cloud-admin/r2-cloud-admin-sub-project/sub-project.module';
import {StatisticsModule} from './main/statistics/statistics.module';
import {CustomerGroupModule} from './main/customer-group/customer-group.module';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {StatusModule} from './main/status/status.module';
import {LinuxServerModule} from './main/linux-server/linux-server.module';
import {R2CloudAdminModule} from './main/r2-cloud-admin/r2-cloud-admin.module';
import {IAMModule} from './main/iam/iam.module';
import {ErrorModule} from './error/error.module';
import {Html5Module} from './html5/html5.module';
import {ImageFileModule} from './main/image-file/image-file-module';
import {RegisterModule} from './main/register/register.module';
import {ManagerUserAccessPeriodModule} from './main/manager-user-access-period/manager-use-access-period.module';
import {ScriptExecutionModule} from './main/script-execution/script-execution.module';
import {AdAdminModule} from './main/ad-admin/ad-admin.module';
import {MyPrintsModule} from './main/my-prints/my-prints.module';
import {DotNetServerPluginModule} from './main/server-plugin/server-plugin.module';
import {MonitoringModule} from './main/monitoring/monitoring.module';
import {CustomerGroupsDashboardModule} from './main/customer-groups-dashboard/customer-groups-dashboard.module';
import {VpnMonitoringConfigurationModule} from './main/monitoring/monitoring-details/vpn-monitoring/vpn-monitoring-configuration/vpn-monitoring-configuration.module';
import {ProcessorUsageModule} from './main/monitoring/monitoring-details/processor-usage/processor-usage.module';
import {MemoryUsageModule} from './main/monitoring/monitoring-details/memory-usage/memory-usage.module';
import {HdUsageModule} from './main/monitoring/monitoring-details/hd-monitoring/hd-usage/hd-usage.module';
import {MatIconRegistry} from '@angular/material/icon';
import {VpnUsageModule} from './main/monitoring/monitoring-details/vpn-monitoring/vpn-usage/vpn-usage.module';
import {HipcomModule} from './main/partners/hipcom/hipcom.module';
import {MyDocsModule} from './main/my-docs/my-docs.module';
import {MarketplaceModule} from './main/marketplace/marketplace.module';
import {PluginMessageModule} from './main/plugin-message/plugin-message.module';
import {CustomLayoutModule} from './main/custom-layout/custom-layout.module';
import {TextFileModule} from './main/textFile/text-file-module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FirewallFqdnModule} from './main/firewall-fqdn/firewall-fqdn.module';
import {AdUserServiceExternalModule} from './main/ad-user-service-external/ad-user-service-external.module';
import {AdUserPoolModule} from './main/ad-user-pool/ad-user-pool.module';
import {AdUserPoolRelationshipModule} from './main/ad-user-pool-relationship/ad-user-pool-relationship.module';
import {DigitalCertificateModule} from './main/digital-certificate/digital-certificate.module';
import {MyUploadsModule} from './main/my-uploads/my-uploads.module';
import {GatewayServerModule} from './main/gateway-server/gateway-server.module';
import {DesktopServerPoolModule} from './main/desktop-server-pool/desktop-server-pool.module';
import {RemoteAppRelationshipModule} from './main/remote-app-relationship/remote-app-relationship.module';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        UserAuthorityModule,
        ProjectModule,
        CustomerModule,
        DesktopModule,
        LoginModule,
        HomeModule,
        ManagerUserModule,
        ProfileModule,
        AdDomainModule,
        AdUserModule,
        AdUserServiceExternalModule,
        AdGroupModule,
        DesktopServerModule,
        RemoteAppModule,
        R2CloudAdminModule,
        StatisticsModule,
        StatusModule,
        ManagerUserAndAdUserModule,
        SubProjectModule,
        CustomerGroupModule,
        LinuxServerModule,
        IAMModule,
        ErrorModule,
        ImageFileModule,
        TextFileModule,
        RegisterModule,
        MonitoringModule,
        ScriptExecutionModule,
        ManagerUserAccessPeriodModule,
        AdAdminModule,
        Html5Module,
        MyPrintsModule,
        MyDocsModule,
        DotNetServerPluginModule,
        CustomerGroupsDashboardModule,
        VpnMonitoringConfigurationModule,
        ProcessorUsageModule,
        VpnUsageModule,
        MemoryUsageModule,
        HdUsageModule,
        HipcomModule,
        MarketplaceModule,
        PluginMessageModule,
        CustomLayoutModule,
        MatFormFieldModule,
        MatCardModule,
        MatToolbarModule,
        // Html5v2Module
        FirewallFqdnModule,
        AdUserPoolModule,
        AdUserPoolRelationshipModule,
        DigitalCertificateModule,
        MyUploadsModule,
        GatewayServerModule,
        DesktopServerPoolModule,
        RemoteAppRelationshipModule
    ],
    exports: [],
    declarations: []


})
export class KluhManagerModule {
    constructor(public matIconRegistry: MatIconRegistry) {
        matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    }
}
