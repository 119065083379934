import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomainPath} from './models-domain';
import {Observable, of} from 'rxjs';
import {filter, map, shareReplay} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DomainPathService {

    private _domainPath$ = this.http.get<DomainPath>('/assets/js/r2cloud-domain.json')
        .pipe(shareReplay(1), filter((x) => !!x));
    private domainPathObj: DomainPath = null;

    constructor(private http: HttpClient) {
        this.domainPath$.subscribe((path) => {
            if (path) {
                this.domainPathObj = path;
            }
        });
    }

    private static buildHttpUrl(secure: boolean, domain: string): string {
        if (secure) {
            return `https://${domain}/`;
        }
        return `https://${domain}/`;
    }

    private static buildWebSocketUrl(secure: boolean, domain: string): string {
        if (secure) {
            return `wss://${domain}/`;
        }
        return `ws://${domain}/`;
    }

    get domainPath$(): Observable<DomainPath> {
        return this._domainPath$;
    }

    get domainPath(): DomainPath {
        return this.domainPathObj;
    }
    
    get apiURL(): string {
        return '/api/';
    }

    get apiURL$(): Observable<string> {
        return of('/api/');
    }

    get apiWebSocketURL$(): Observable<string> {
        return of(DomainPathService.buildWebSocketUrl(window.location.protocol === 'https:',  window.location.host + '/api/'));
    }

    get terminalURL(): string {
        return DomainPathService.buildHttpUrl(this.domainPathObj.secure, this.domainPathObj.terminalDomain);
    }

    get terminalURL$(): Observable<string> {
        return this.domainPath$.pipe(map((x) => {
            return DomainPathService.buildHttpUrl(x.secure, x.terminalDomain);
        }));
    }

    get imageCacheURL$(): Observable<string> {
        return this.domainPath$.pipe(map((x) => {
            return DomainPathService.buildHttpUrl(x.secure, x.image);
        }));
    }

    get imageCacheURL(): string {
        return DomainPathService.buildHttpUrl(this.domainPathObj.secure, this.domainPathObj.image);
    }


    get monitoringURL(): string {
        return DomainPathService.buildHttpUrl(this.domainPathObj.secure, this.domainPathObj.monitoringDomain);
    }

    get monitoringWSS(): string {
        return DomainPathService.buildWebSocketUrl(this.domainPathObj.secure, this.domainPathObj.monitoringDomain);
    }

    get monitoringURL$(): Observable<string> {
        return this.domainPath$.pipe(map((x) => {
            return DomainPathService.buildHttpUrl(x.secure, x.monitoringDomain);
        }));
    }

    get monitoringWebSocketURL$(): Observable<string> {
        return this.domainPath$.pipe(map((x) => {
            return DomainPathService.buildWebSocketUrl(x.secure, x.monitoringDomain);
        }));
    }


    get customLayoutURL(): string {
        return DomainPathService.buildHttpUrl(this.domainPathObj.secure, this.domainPathObj.customLayoutDomain);
    }

    get customLayoutURL$(): Observable<string> {
        return this.domainPath$.pipe(map((x) => {
            return DomainPathService.buildHttpUrl(x.secure, x.customLayoutDomain);
        }));
    }

    get staticURL(): string {
        return DomainPathService.buildHttpUrl(this.domainPathObj.secure, this.domainPathObj.staticDomain);
    }

    get staticURL$(): Observable<string> {
        return this.domainPath$.pipe(map((x) => {
            return DomainPathService.buildHttpUrl(x.secure, x.staticDomain);
        }));
    }

    get secureInt(): number {
        if (this.domainPathObj.secure) {
            return 1;
        }
        return 0;
    }
}
