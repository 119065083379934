<div class="dialog-content-wrapper">
    <form [formGroup]="myForm" (ngSubmit)="myForm.valid && onSubmit()">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">R2 Scaling</span>
            </mat-toolbar-row>
        </mat-toolbar>


        <div class="mat-white-bg mat-elevation-z2 p-20" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>

            <mat-form-field appearance="outline" style="width: 100%;" [hidden]="!desktopServerPool?.groupId"
                            class="mb-10">
                <mat-label>GroupId</mat-label>
                <input matInput formControlName="groupId" placeholder="GroupId">
                <mat-error>
                    {{ myForm.get('groupId').errors | error }}
                </mat-error>
            </mat-form-field>


            <mat-form-field appearance="outline" class="mb-10" style="width: 100%;">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name" placeholder="Nome">
                <mat-error>
                    {{ myForm.get('name').errors | error }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="mb-10">
                <mat-label>Domínio</mat-label>
                <mat-select placeholder="Domínio"
                            formControlName="adDomainId">
                    <mat-option *ngFor="let adDomain of adminService.adDomains"
                                [value]="adDomain.id">
                        {{ adDomain.name }} - {{ adDomain.domainName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style="height: 80px;">
                <mat-label>Servidor de base</mat-label>
                <mat-select placeholder="Servidor de base"
                            formControlName="appBaseDesktopServerId">
                    <mat-option *ngFor="let desktopServer of desktopServerListBySelectedADDomain()"
                                [value]="desktopServer.id">
                        {{ desktopServer.hostname }} - {{ desktopServer.connectionFQDN }}
                        :{{ desktopServer.connectionPort }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-slide-toggle formControlName="notOpenAppFromAppBaseDesktopServer" name="notOpenAppFromAppBaseDesktopServer" class="mb-40">
                Utilize o servidor de base para abrir o aplicativo somente se não houver outros servidores disponíveis.
             </mat-slide-toggle>


            <mat-form-field class="full-width h-70" appearance="outline">
                <mat-label>Adicionar servidores comuns ao pool</mat-label>
                <mat-select placeholder="Adicionar servidores comuns ao pool" [formControl]="desktopServersIdsCtrl" [multiple]="true" #multiSelect>
                    <mat-option *ngFor="let desktopServer of filteredDesktopServersMulti" [value]="desktopServer.id">
                        {{desktopServer.hostname}} - {{desktopServer.connectionFQDN}}:{{desktopServer.connectionPort}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{desktopServersIdsCtrl.errors | error}}
                </mat-error>
            </mat-form-field>
            <span class="pl-5 font-size-12" style="color: #555555">Para servidores em autoescalamento que usam Golden Image, é necessário utilizar a chave privada salva durante a criação desse R2 Scaling.</span>











            <div class="mt-50">

                <div fxLayout="row" fxLayoutAlign="start start">
                    <h2 style="padding: 0 !important;margin: 0 !important;">R2 Security</h2>
                    <mat-slide-toggle [formControl]="activateR2SecurityControl" class="ml-20 mt-2">
                        Ativar
                    </mat-slide-toggle>
                </div>


                <div fxLayout="column" fxLayoutAlign="space-between stretch"  [hidden]="!activateR2SecurityControl.value">

                    <mat-form-field appearance="outline" class="mt-30">
                        <mat-label>Projeto onde está o R2 Security Server</mat-label>
                        <mat-select placeholder="Selecione o Projeto onde está o R2 Security Server"
                                    [formControl]="customerGroupForm">
                            <mat-option *ngFor="let customerGroup of customerGroups"
                                        [value]="customerGroup.id">
                                {{ customerGroup.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>R2 Security Server</mat-label>
                        <mat-select placeholder="Selecione o R2 Security Server"  formControlName="gatewayServerId">
                            <mat-option *ngFor="let gatewayServer of gatewayServers"
                                        [value]="gatewayServer.id">
                                {{ gatewayServer.name }} | {{ gatewayServer.fqdn }}:{{ gatewayServer.port }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div fxLayout="row" fxLayoutAlign="start start"  [hidden]="!myForm.get('gatewayServerId').value">

                        <mat-form-field appearance="outline" *ngIf="ServerConnectionType" style="width: 340px;">
                            <mat-label>Server Connection Type</mat-label>
                            <mat-select placeholder="Escolha o tipo de conexão" formControlName="gatewayServerNatConfigServerConnectionType">
                                <mat-option [value]="ServerConnectionType?.PUBLIC_IP">
                                    {{ ServerConnectionType?.PUBLIC_IP }}
                                </mat-option>
                                <mat-option [value]="ServerConnectionType?.PRIVATE_IP">
                                    {{ ServerConnectionType?.PRIVATE_IP }}
                                </mat-option>
                                <mat-option [value]="ServerConnectionType?.CONNECTION_FQDN">
                                    {{ ServerConnectionType?.CONNECTION_FQDN }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 300px;" class="mb-10 ml-25" [hidden]="!activateCustomPortControl.value">
                            <mat-label>Porta NAT customizada</mat-label>
                            <input matInput formControlName="gatewayServerNatConfigServerConnectionCustomPort" pattern="[0-9]*" max="50000" min="1">
                            <mat-error *ngIf="myForm.get('gatewayServerNatConfigServerConnectionCustomPort')?.errors?.pattern">
                                Por favor, insira apenas números.
                            </mat-error>
                            <mat-error *ngIf="myForm.get('gatewayServerNatConfigServerConnectionCustomPort')?.errors?.required">
                                Este campo é obrigatório.
                            </mat-error>
                            <mat-error
                                *ngIf="myForm.get('gatewayServerNatConfigServerConnectionCustomPort')?.errors?.max || myForm.get('gatewayServerNatConfigServerConnectionCustomPort')?.errors?.min">
                                Por favor, insira um número entre 1 e 50000.
                            </mat-error>
                            <mat-error *ngIf="myForm.errors">
                                {{ myForm.errors | error }}
                            </mat-error>
                        </mat-form-field>

                        <mat-slide-toggle [formControl]="activateCustomPortControl" class="mt-20 ml-20">
                            Usar porta customizada
                        </mat-slide-toggle>

                    </div>

                    <div *ngIf="numberOfDoorsInRange() > 0" class="pl-5 mb-15" [ngClass]="serverLimitExceeded() ? 'warn-color font-size-14' : 'common-color font-size-12'">
                        <div>Importante, a quantidade de portas não pode ser menor que a quantidade de servidores.</div>
                        <table>
                            <tr>
                                <td>Quantidade de servidores:</td>
                                <td>{{allDesktopServers.length}}</td>
                            </tr>
                            <tr>
                                <td>Quantidade de portas:</td>
                                <td>{{numberOfDoorsInRange()}}</td>
                            </tr>
                        </table>
                    </div>


                        <mat-form-field appearance="outline" class="width-100-percent"  [hidden]="!myForm.get('gatewayServerId').value">
                            <mat-label>Min Range de Porta de destino do R2 Security (4000-6000)</mat-label>
                            <input matInput formControlName="gatewayServerNatConfigDestinationPortRangeMin" placeholder="Min Range de Porta de destino do R2 Security"
                                   pattern="[0-9]*" max="6000" min="4000">

                            <mat-error
                                *ngIf="myForm.get('gatewayServerNatConfigDestinationPortRangeMin')?.errors?.minGreaterThanMax">
                                {{ myForm.get('gatewayServerNatConfigDestinationPortRangeMin').errors | error }}
                            </mat-error>
                            <mat-error *ngIf="myForm.errors">
                                {{ myForm.errors | error }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="width-100-percent" [hidden]="!myForm.get('gatewayServerId').value">
                            <mat-label>Max Range de Porta de destino do R2 Security (4000-6000)</mat-label>
                            <input matInput formControlName="gatewayServerNatConfigDestinationPortRangeMax" placeholder="Max Range de Porta de destino do R2 Security"
                                   pattern="[0-9]*" max="6000" min="4000">
                            <mat-error
                                *ngIf="myForm.get('gatewayServerNatConfigDestinationPortRangeMax')?.errors?.minGreaterThanMax">
                                {{ myForm.get('gatewayServerNatConfigDestinationPortRangeMax').errors | error }}
                            </mat-error>
                            <mat-error *ngIf="myForm.errors">
                                {{ myForm.errors | error }}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline"  [hidden]="!myForm.get('gatewayServerId').value" style="width:150px;">
                            <mat-label>Protocolo</mat-label>
                            <mat-select placeholder="Escolha o protocolo" formControlName="gatewayServerNatConfigProtocol">
                                <mat-option [value]="'tcp'">TCP</mat-option>
                                <mat-option [value]="'udp'">UDP</mat-option>
                                <mat-option [value]="'tcp/udp'">TCP/UDP</mat-option>
                            </mat-select>
                        </mat-form-field>




                    <mat-form-field appearance="outline" style="width: 100%;"  [hidden]="!myForm.get('gatewayServerId').value">
                        <mat-label>FQDN do gateway para HTML5</mat-label>
                        <input matInput formControlName="gatewayFQDN" placeholder="gatewayFQDN">
                        <mat-error>
                            {{ myForm.get('gatewayFQDN').errors | error }}
                        </mat-error>
                    </mat-form-field>



                    <mat-slide-toggle formControlName="gatewayServerNatConfigOpenAccess" name="gatewayServerNatConfigOpenAccess" class="mt-10 mb-40"   [hidden]="!myForm.get('gatewayServerId').value">
                        Acesso Liberado para todos
                    </mat-slide-toggle>



                </div>


            </div>















            <mat-form-field class="full-width min-width-230 mt-20">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Observações</mat-label>
                    <textarea rows="2" matInput formControlName="comment"
                              class="full-width"></textarea>
                </mat-form-field>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span>Salvar</span>
            </button>
            <button type="button" mat-raised-button color="warn"
                    *ngIf="desktopServerPool.id"
                    (click)="onRemove()"
                    aria-label="Deletar">
                <span>Deletar</span>
            </button>
            <button type="button" mat-raised-button color="warn"
                    *ngIf="desktopServerPool.id"
                    (click)="onRemoveFromProject()"
                    aria-label="Deletar">
                <span>Remover do projeto</span>
            </button>
            <button type="button" mat-raised-button color="primary"
                    (click)="onCancel()">Cancelar
            </button>

            <div *ngIf="desktopServerPool?.groupId">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu"
                       >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px">
                    <button mat-menu-item (click)="onGeneratePrivateKey()">
                        <mat-icon>vpn_key</mat-icon>
                        <span>Nova Chave</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: desktopServerPool; type: 'DesktopServerPool'"
                            (click)="onJaversHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: desktopServerPool; type: 'DesktopServerPool'"
                            (click)="onJaversAllHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico completo</span>
                    </button>
                </mat-menu>
            </div>
        </div>

    </form>
</div>

