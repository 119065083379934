import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SubProjectDaoService} from '../sub-project-dao.service';
import {convertToFormGroup, markAsTouched} from '../../../../helpers/kluh';
import {ComponentCleaner} from '../../../../component-cleaner';
import {CustomerGroup, Project, SubProject} from '../../../../models';
import {ProjectDaoService} from '../../../project/project-dao.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {EntityHistoryComponent} from '../../../javers/entity-history/entity-history.component';

@Component({
    selector: 'app-sub-project-create-edit',
    templateUrl: './sub-project-create-edit.component.html',
    styleUrls: ['./sub-project-create-edit.component.scss']
})
export class SubProjectCreateEditComponent extends ComponentCleaner {
    public myForm: FormGroup;
    public subProject: SubProject;
    public project: Project = {
        id: null, name: null, hasSubProjects: null, active: true, comment: null,
        modified: null,
        optlock: null
    };
    public projectNameFilterControl: FormControl = new FormControl();
    public projects: Project[];
    public customerGroup: CustomerGroup;
    public projectType: string;

    constructor(private dialogRef: MatDialogRef<SubProjectCreateEditComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private dialog: MatDialog,
                private fb: FormBuilder,
                private router: Router,
                private subProjectDaoService: SubProjectDaoService,
                private projectDaoService: ProjectDaoService,
    ) {
        super();
        if (data && data.projectType) {
            this.projectType = data.projectType;
        }
        if (data && data.customerGroup) {
            this.customerGroup = data.customerGroup;
        }

        if (data && data.subProject) {
            this.subProject = data.subProject;
        } else {
            this.subProject = {
                id: null,
                name: null,
                comment: null,
                active: true,
                projectId: null,
                customerGroupId: null,
                adDomainIds: [],
                desktopServerIds: [],
                desktopServerPoolIds: [],
                linuxServerIds: [],
                modified: null,
                optlock: null
            };
            if (data && data.projectId) {
                this.subProject.projectId = data.projectId;
            }
            if (data && data.customerGroupId) {
                this.subProject.customerGroupId = data.customerGroupId;
            }
            if (data && data.customerGroup) {
                this.subProject.customerGroupId = data.customerGroup.id;
            }

        }

        if (data && data.projects) {
            this.projects = data.projects;
        }

        this.projectDaoService.getMyProjects().subscribe((projectsFromDAO) => {
            for (const project of projectsFromDAO) {
                if (this.projects !== null && typeof this.projects !== 'undefined') {
                    const projectExists = this.projects.find((projectChecker) => {
                        return project.id === projectChecker.id;
                    });
                    if (!(projectExists && projectExists.id)) {
                        this.projects.push(project);
                    }
                }
            }
        });


        // this.myForm = this.fb.group(convertToFormGroup(this.subProject), {asyncValidator: this.subProjectDaoService.validator});
        this.myForm = this.fb.group(convertToFormGroup(this.subProject));


        this.projectNameFilterControl.valueChanges.subscribe(() => {
            if (this.projectType === 'new-shared') {
                markAsTouched(this.myForm);
            }
        });

    }

    private removeSpecialChars(text: string): string {
        if (text) {
            return text.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');
        }
        return text;
    }

    onSubmit(): void {
        const subProject: SubProject = this.myForm.value;
        if (this.projectType === 'new-shared' || this.projectType === 'private') {
            this.project.hasSubProjects = true;
            // Cria o novo compartilhamento
            if (this.projectType === 'private') {
                this.project.name = this.removeSpecialChars(subProject.name);
                this.project.hasSubProjects = false;
            } else {
                this.project.name = this.removeSpecialChars(this.projectNameFilterControl.value);
            }
            if (this.projectType === 'new-shared') {
                subProject.name = this.removeSpecialChars(this.projectNameFilterControl.value);
            }
            this.projectDaoService.create(this.project).subscribe((savedProject) => {
                subProject.projectId = savedProject.id;
                this.onCreateSubProject(subProject);
            });
        } else {
            this.onCreateSubProject(subProject);
        }
    }

    onCreateSubProject(subProject: SubProject): void {
        let subProject$;
        if (subProject.id) {
            subProject$ = this.subProjectDaoService.save(subProject);
        } else {
            subProject$ = this.subProjectDaoService.create(subProject);
        }
        subProject$.subscribe((savedSubProject) => {
            this.dialogRef.close(savedSubProject);
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }


    onRemove(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse Projeto?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.subProjectDaoService.remove(this.subProject.id).subscribe(() => {
                    this.dialogRef.close();
                    this.router.navigate(['/register']);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.subProject.id, this.subProjectDaoService, this.dialog);
    }
    onJaversAllHistory(): void {
        EntityHistoryComponent.openAllHistory(this.subProject.id, this.subProjectDaoService, this.dialog);
    }
}
