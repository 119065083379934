/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-06-28 15:09:03.

export interface FirebaseUserDetails {
    id: string | null;
    email: string | null;
    provider: ManagerUserProvider | null;
    issuer: string | null;
    displayName: string | null;
    picture: string | null;
    ip: string | null;
    userAgent: string | null;
    emailVerified: boolean;
    credentialsExpiring: boolean;
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    enabled: boolean;
    authorities: (GrantedAuthority | null)[];
    managerUser: ManagerUser;
}

export interface CredentialsExpiring {
    value: boolean;
}

export interface RemoteAppLog extends BaseAccessLog {
    serviceUser: boolean | null;
    remoteAppName: string | null;
    remoteAppId: number | null;
    remoteAppPath: string | null;
}

export interface ServerLog extends BaseAccessLog {
    localAdmin: boolean | null;
}

export interface Customer extends BaseDTOIdLong {
    name: string | null;
    cnpj: string | null;
    province: string | null;
    city: string | null;
    neighborhood: string | null;
    address: string | null;
    addressAddOn: string | null;
    addressNumber: number | null;
    zipCode: string | null;
    phone: string | null;
    imageFileId: number | null;
    imageUUID: string | null;
    customerGroupId: number | null;
    uuid: string | null;
    blockAllAccess: boolean | null;
    contractedUsers: number | null;
}

export interface CustomerGroup extends BaseDTOIdLong {
    name: string | null;
    contractedUsers: number | null;
    imageFileId: number | null;
    imageUUID: string | null;
    subProjectIds: number[];
    managerGroupIds: number[];
    softwareCompanyIds: number[];
    customerIds: number[];
    customPortalTemplateId: number | null;
    backgroundImageTemplateId: number | null;
    htmlId: number | null;
    url: string | null;
    exceededMaximumUsersMessage: string | null;
    partnerCustomerGroupId: number | null;
    blockAllAccess: boolean | null;
    blockAllAccessMessage: string | null;
    localAdminUsersOnly: boolean | null;
}

export interface ExceededMaximumUsers {
    exceededMaximumUsers: boolean;
    message: string;
}

export interface CustomerGroupLicensesDashboard {
    customerGroupId: number;
    customerGroupName: string;
    customerGroupLocalAdminUsersOnly: boolean;
    partnerCustomerGroupId: number | null;
    customerGroupImageUUID: string | null;
    amountOfContractedUsers: number | null;
    amountOfManagerUsers: number;
    differenceBetweenContractedUsersAndCreatedUsers: number;
    customerGroupCreatedAt: Date | null;
    amountOfCustomerGroupsADUsers: number | null;
    amountOfCustomerGroupsADUsersPool: number | null;
    amountOfCustomerGroupsExternalADUsers: number | null;
    amountOfDesktopServer: number | null;
    amountOfLinuxServer: number | null;
}

export interface BaseServer extends BaseDTOIdLong {
    publicIp: string | null;
    privateIp: string | null;
    clientId: string | null;
    hostname: string | null;
    connectionFQDN: string | null;
    connectionPort: number | null;
    gatewayServerNatConfigActive: boolean | null;
    lastLoginAt: Date | null;
}

export interface BaseServerPrivateKey {
    serverId: number | null;
    privateKey: string | null;
}

export interface ADAdmin extends BaseDTOIdLong {
    adDomainId: number | null;
    adUserId: number | null;
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ADDomain extends BaseDTOIdLong {
    customerGroupId: number | null;
    domainName: string | null;
    name: string | null;
    adServerId: number | null;
    subProjectIds: number[];
}

export interface ADGroup extends BaseDTOIdLong {
    name: string | null;
    prefix: string | null;
    adDomainId: number | null;
    adUserIds: number[];
    adUserPoolRelationshipIds: number[];
    subProjectId: number | null;
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ADUser extends BaseDTOIdLong {
    login: string | null;
    managerUserId: number | null;
    adDomainId: number | null;
    serviceUser: boolean;
    adGroupIds: number[];
    managerUserServiceIds: number[];
    subProjectIds: number[];
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ADUserHtml5 {
    adUserId: number | null;
    login: string | null;
    password: string | null;
    adDomainId: number | null;
}

export interface ADUserWithPassword {
    adUser: ADUser | null;
    adUserAlreadyExists: boolean;
    askThePassword: boolean;
    adUserPassword: string | null;
    subProjectId: number | null;
}

export interface FilterByEmailAndAdDomainIdAndSubProjectId {
    managerUserEmail: string | null;
    adDomainId: number | null;
    subProjectId: number | null;
}

export interface ManagerUserEmailsSubProjectIdADDomainId {
    emails: string[];
    subProjectId: number;
    adDomainId: number;
    adGroupId: number;
}

export interface ADUserPool extends BaseDTOIdLong {
    login: string | null;
    adDomainId: number | null;
    serviceUser: boolean;
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ADUserPoolEmailRelationship {
    email: string | null;
    createdAt: Date | null;
}

export interface ADUserPoolInfo {
    login: string | null;
    customerGroupName: string | null;
    customerGroupId: number | null;
    emailsRelationship: ADUserPoolEmailRelationship[] | null;
    serviceUser: boolean | null;
    subProjectName: string | null;
}

export interface NumberADUsersPoolADDomain {
    amount: number;
    adDomainId: number;
}

export interface ADUserPoolRelationship extends BaseDTOIdLong {
    adUserPoolId: number | null;
    subProjectId: number | null;
    managerUserId: number | null;
    adGroupIds: number[];
    remoteAppIds: number[];
    managerUserServiceIds: number[];
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ADUserPoolRelationshipLogoff {
    adUserPoolLogin: string;
    desktopServerId: number;
    adDomainId: number;
}

export interface AmountOfADUserPoolRelationshipByADUserPool {
    adUserPoolID: number;
    amountOfADUserPoolRelationship: number;
}

export interface ADUserServiceExternal extends BaseDTOIdLong {
    adUserId: number | null;
    subProjectId: number | null;
    managerUserIds: number[];
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ClientSettings extends BaseDTOIdLong {
    managerUserId: number | null;
    clientId: string | null;
    enableMic: boolean;
    enableAudio: boolean;
    autoQuality: boolean;
    connectUsingVpn: boolean;
    enableClipboard: boolean;
    enablePrint: boolean;
    enableAllDrives: boolean;
    multiMon: boolean;
    driveList: string[];
}

export interface DesktopServerCredentials {
    localAdmin: string | null;
    localAdminPassword: string | null;
}

export interface DesktopServer extends BaseServer {
    adDomainId: number | null;
    gatewayFQDN: string | null;
    internalPort: number | null;
    remoteAppIds: number[];
    serverPluginTemplateIds: number[];
    firewallFQDNIds: number[];
    disableUpdate: boolean;
    desktopServerPoolId: number | null;
    disposable: boolean | null;
    adIp: string | null;
}

export interface DesktopServerIdWithVersion {
    desktopServerId: number | null;
    version: string | null;
}

export interface DesktopServerPrivateKey extends BaseServerPrivateKey {
}

export interface DesktopServerWithSubProject {
    desktopServerDTO: DesktopServer | null;
    subProjectIDs: number[];
}

export interface LocalAdminCredentials {
    login: string;
    password: string;
    clientId: string;
}

export interface DesktopServerSession {
    userName: string;
    startTime: Date;
    sessionId: string;
    subscriptions: DesktopServerSubscription[];
}

export interface DesktopServerSubscription {
    destination: string;
    subscriptionId: string;
}

export interface ServerPresence {
    serverId: number;
    hostname: string;
    connectionFQDN: string;
    connectionPort: number | null;
    serverType: ServerType;
    adDomainId: number | null;
    clientId: string | null;
    clientVersion: string;
    subProjectIds: number[];
    sessions: DesktopServerSession[];
}

export interface DesktopServerPool extends BaseDTOIdLong {
    groupId: string | null;
    appBaseDesktopServerId: number | null;
    adDomainId: number | null;
    name: string | null;
    notOpenAppFromAppBaseDesktopServer: boolean | null;
    gatewayServerId: number | null;
    gatewayServerNatConfigDestinationPortRangeMin: number | null;
    gatewayServerNatConfigDestinationPortRangeMax: number | null;
    gatewayServerNatConfigOpenAccess: boolean | null;
    gatewayServerNatConfigServerConnectionType: ServerConnectionType | null;
    gatewayServerNatConfigServerConnectionCustomPort: number | null;
    gatewayServerNatConfigProtocol: string | null;
    gatewayFQDN: string | null;
}

export interface DesktopServerPoolWithDesktopServerIds {
    desktopServerPool: DesktopServerPool;
    desktopServerIds: number[];
}

export interface FirewallFQDN extends BaseDTOIdLong {
    fqdn: string | null;
    desktopServerId: number | null;
    linuxServerId: number | null;
}

export interface LinuxServer extends BaseServer {
    firewallFQDNIds: number[];
}

export interface LinuxServerPrivateKey extends BaseServerPrivateKey {
}

export interface LinuxServerWithSubProject extends BaseDTOIdLong {
    linuxServerDTO: LinuxServer | null;
    subProjectIDs: number[];
}

export interface ChartPoint {
    x: number;
    y: number;
}

export interface Network extends BaseDTOIdLong {
    name: string | null;
    speedMbps: number | null;
    ipv4: string | null;
    macAddress: string | null;
    description: string | null;
    status: string | null;
    ipv4Mask: string | null;
    dhcpServer: string | null;
    gateway: string | null;
    primaryDNS: string | null;
    secondaryDNS: string | null;
    ipv6: string | null;
    deviceType: string | null;
    mobileConnection: string | null;
    wifiStatus: string | null;
    wifiSsid: string | null;
    wifiBssid: string | null;
    firstSeenIn: Date | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    desktopServerId: number | null;
    linuxServerId: number | null;
    dhcp: boolean | null;
}

export interface RemoteApp extends BaseDTOIdLong {
    name: string | null;
    app: string | null;
    alias: string | null;
    appArgs: string | null;
    serviceApp: boolean;
    desktopServerId: number | null;
    adUserIds: number[];
    adUserPoolRelationshipIds: number[];
    adGroupIds: number[];
    imageBase64: string | null;
    imageUUID: string | null;
    openDelay: number | null;
    subProjectId: number | null;
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
    localUser: boolean | null;
}

export interface RemoteAppHtml5 {
    remoteAppId: number | null;
    appName: string | null;
    app: string | null;
    appArgs: string | null;
    imageBase64: string | null;
    imageId: number | null;
    imageUUID: string | null;
    adUserId: number | null;
    poolUser: boolean | null;
    desktopServerId: number | null;
    customerGroupId: number | null;
    subProjectId: number | null;
    status: RemoteAppReadyStatus | null;
}

export interface RemoteAppRelationship extends BaseDTOIdLong {
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
    remoteAppId: number | null;
    desktopServerId: number | null;
    desktopServerPoolId: number | null;
}

export interface DotNetServerAgentFile extends BaseDTOIdLong {
    md5: string | null;
    version: string | null;
    fileUUID: string | null;
}

export interface DotNetServerPlugin extends BaseDTOIdLong, IDotNetServerPlugin {
    serverPluginFileIds: number[];
}

export interface DotNetServerPluginDependencyFile extends BaseDTOIdLong {
    name: string | null;
    md5: string | null;
    version: string | null;
    fileUUID: string | null;
}

export interface DotNetServerPluginFile extends BaseDTOIdLong {
    md5: string | null;
    version: string | null;
    minClientVersion: string | null;
    minClientVersionExpression: ClientVersionExpression | null;
    maxClientVersion: string | null;
    maxClientVersionExpression: ClientVersionExpression | null;
    serverPluginId: number | null;
    dependencyIds: number[];
    fileUUID: string | null;
}

export interface DotNetServerPluginTemplate extends BaseDTOIdLong {
    name: string | null;
    serverPluginIds: number[];
}

export interface DotNetServerAgentUpdaterFile extends BaseDTOIdLong {
    md5: string | null;
    version: string | null;
    fileUUID: string | null;
}

export interface SubProject extends BaseDTOIdLong {
    name: string | null;
    projectId: number | null;
    customerGroupId: number | null;
    adDomainIds: number[];
    desktopServerIds: number[];
    linuxServerIds: number[];
    desktopServerPoolIds: number[];
}

export interface DigitalCertificate extends BaseDTOIdLong {
    name: string | null;
    data: any | null;
    managerUserId: number | null;
    customerGroupId: number | null;
    size: number | null;
    deleting: boolean | null;
    expirationDate: Date | null;
    createdAt: Date | null;
    fileUUID: string | null;
}

export interface DigitalCertificateInstall extends BaseDTOIdLong {
    managerUserId: number | null;
    digitalCertificateId: number | null;
    desktopServerId: number | null;
    adUserId: number | null;
    adUserPoolId: number | null;
    adUserLogin: string | null;
    error: boolean;
    changing: boolean;
    deleting: boolean;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface DownloadFile extends BaseDTOIdLong {
    name: string | null;
    data: any | null;
    user: string | null;
    clientId: string | null;
    size: number | null;
    createdAt: Date | null;
    fileUUID: string | null;
}

export interface DownloadFileEvent {
    id: number | null;
    name: string | null;
    sessionCookie: string | null;
}

export interface GatewayAlias {
    name: string;
    value: string;
}

export interface GatewayServer extends BaseDTOIdLong {
    name: string | null;
    fqdn: string | null;
    secure: boolean | null;
    port: number | null;
    login: string | null;
    administrationIps: string | null;
    customerGroupId: number | null;
    online: boolean | null;
    lastErrorMessage: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
    lastStatusUpdatedAt: Date | null;
}

export interface GatewayServerInUse {
    customerGroupName: string;
    subProjectName: string;
    gatewayServerId: number;
    gatewayServerNatInfo: GatewayServerNatInfo;
}

export interface GatewayServerNatInfo {
    hostname: string;
    serverType: ServerType;
    gatewayServerNatConfigId: number;
    createdAt: Date;
}

export interface GatewayServerWithPassword {
    gatewayServerDTO: GatewayServer | null;
    password: string | null;
}

export interface GatewayServerNatConfigCheck {
    name: string | null;
    functionName: string | null;
    status: boolean | null;
    gatewayValue: string | null;
    databaseValue: string | null;
}

export interface GatewayServerNatConfig extends BaseDTOIdLong {
    gatewayServerId: number | null;
    destinationPort: number | null;
    desktopServerId: number | null;
    openAccess: boolean | null;
    serverConnectionType: ServerConnectionType | null;
    serverConnectionCustomPort: number | null;
    protocol: string | null;
    linuxServerId: number | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface ImageFile extends BaseDTOIdLong {
    image: any | null;
}

export interface EmailSubProjectId {
    email: string;
    subProjectId: number;
    hasRole: boolean;
    exists: boolean;
    existsInThisSubProject: boolean;
}

export interface LoggedUser {
    email: string;
    ip: string;
}

export interface ManagerUserCustomerGroupData {
    login: string;
    managerUserEmail: string;
    customerName: string;
    customerGroupName: string;
}

export interface ManagerUser extends BaseDTOIdLong {
    email: string | null;
    displayName: string | null;
    picture: string | null;
    province: string | null;
    city: string | null;
    neighborhood: string | null;
    address: string | null;
    addressAddOn: string | null;
    addressNumber: number | null;
    zipCode: string | null;
    phone: string | null;
    twoFactorAuth: boolean;
    customerId: number | null;
    individualClientConfig: boolean;
    authorityIds: number[];
    imageFileId: number | null;
    imageUUID: string | null;
    managerUserAccessPeriodProfileId: number | null;
}

export interface ManagerUserIdWithLastLogin extends BaseDTOIdLong {
    managerUserId: number | null;
    lastLogin: Date | null;
}

export interface ManagerUserLoginToken {
    email: string | null;
    token: number | null;
}

export interface ManagerUserSimple extends BaseDTOIdLong {
    email: string | null;
    displayName: string | null;
    picture: string | null;
    imageFileId: number | null;
    imageUUID: string | null;
}

export interface ManagerUserWithSubProject extends BaseDTOIdLong {
    managerUserDTO: ManagerUser | null;
    subProjectIDs: number[];
}

export interface AccessStatistics {
    data: number[];
    labels: string[];
    managerUserStatistics: ManagerUserStatistic[];
    from: Date;
    to: Date;
    subProjectId: number;
}

export interface ManagerUserStatistic {
    online: boolean | null;
    id: number | null;
    displayName: string | null;
    email: string | null;
    picture: string | null;
    imageFileId: number | null;
    lastAccess: Date | null;
    totalTime: number | null;
    numberOfAccesses: number | null;
    averagePerDay: number | null;
}

export interface FilterByManagerUserIdAndAdDomainId {
    managerUserId: number | null;
    adDomainId: number | null;
}

export interface ManagerGroup extends BaseDTOIdLong {
    name: string | null;
    managerUserIds: number[];
    customerGroupId: number | null;
}

export interface CanAccess {
    start: string | null;
    end: string | null;
    now: string | null;
    allowed: boolean | null;
}

export interface ManagerUserAccessPeriodProfile extends BaseDTOIdLong {
    name: string | null;
    customerGroupId: number | null;
}

export interface ManagerUserAccessPeriodTime extends BaseDTOIdLong {
    dayOfWeek: number;
    startHours: number;
    startMinutes: number;
    stopHours: number;
    stopMinutes: number;
    managerUserAccessPeriodProfileId: number | null;
}

export interface ManagerUserPermissions extends BaseDTOIdLong {
    managerUserId: number | null;
    admin: boolean;
    customerGroupCreator: boolean;
}

export interface ManagerUserOnline {
    managerUserId: number;
    email: string;
    customerGroupId: number;
    partnerCustomerGroupId: number | null;
    imageFileId: number | null;
    picture: string | null;
    displayName: string | null;
    imageUUID: string | null;
    webSessions: ManagerUserStompSession[];
    clientSessions: ManagerUserStompSession[];
}

export interface ManagerUserOnlineDisconnectRequest {
    managerUserId: number;
    sessionId: string;
}

export interface ManagerUserStompSession {
    userName: string;
    startTime: Date;
    sessionId: string;
    clientId: string;
    clientType: StompClientType;
}

export interface ManagerUserStompSubscription {
    destination: string;
    subscriptionId: string;
}

export interface MarketplaceCategory extends BaseDTOIdLong {
    name: string | null;
}

export interface MarketplaceOrder extends BaseDTOIdLong {
    marketplaceProductId: number | null;
    managerUserId: number | null;
    customerGroupId: number | null;
    readOrder: boolean | null;
    readOrderLastDate: Date | null;
    producerReadOrder: boolean | null;
    producerReadOrderLastDate: Date | null;
    cancel: boolean | null;
    cancelDate: Date | null;
    concluded: boolean | null;
    concludedDate: Date | null;
    description: string | null;
    phone: string | null;
    email: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface MarketplaceOrderComment extends BaseDTOIdLong {
    marketplaceOrderId: number | null;
    managerUserId: number | null;
    email: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface MarketplaceProduct extends BaseDTOIdLong {
    name: string | null;
    shortDescription: string | null;
    marketplaceCategoryId: number | null;
    managerUserId: number | null;
    company: string | null;
    companyWebsite: string | null;
    description: string | null;
    avatarId: number | null;
    avatarUUID: string | null;
    bannerId: number | null;
    bannerUUID: string | null;
    totalRating1: number | null;
    totalRating2: number | null;
    totalRating3: number | null;
    totalRating4: number | null;
    totalRating5: number | null;
    blockedSoftwareCompanyIds: number[] | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface MarketplaceProductComment extends BaseDTOIdLong {
    marketplaceProductId: number | null;
    managerUserId: number | null;
    email: string | null;
    rating: number | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface MarketplaceProductFAQ extends BaseDTOIdLong {
    marketplaceProductId: number | null;
    managerUserId: number | null;
    question: string | null;
    answer: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface MarketplaceProductScreen extends BaseDTOIdLong {
    marketplaceProductId: number | null;
    managerUserId: number | null;
    imageFileId: number | null;
    imageUUID: string | null;
    description: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface SoftwareCompany extends BaseDTOIdLong {
    name: string | null;
    cnpj: string | null;
    province: string | null;
    city: string | null;
    neighborhood: string | null;
    address: string | null;
    addressAddOn: string | null;
    addressNumber: number | null;
    zipCode: string | null;
    phone: string | null;
    imageFileId: number | null;
    imageUUID: string | null;
    createdAt: Date | null;
    updatedAt: Date | null;
}

export interface HipcomBulkUpdateEvent {
    id: number;
    desktopServerId: number;
    active: boolean;
    hipcomBulkUpdateCurrentSoftwareVersions: HipcomBulkUpdateCurrentSoftwareVersion[] | null;
    hipcomBulkUpdateLatestSoftwareVersions: HipcomBulkUpdateLatestSoftwareVersion[] | null;
    hipcomBulkUpdatePaths: HipcomBulkUpdatePath[] | null;
    hipcomBulkUpdateDetailLog: HipcomBulkUpdateDetailLog | null;
    hipcomBulkUpdateCloseSoftwareList: HipcomBulkUpdateCloseSoftware[] | null;
    message: string | null;
    messageTimeInMinutes: number | null;
    status: StatusType;
    messageNow: string | null;
    forcedExecution: boolean;
    startAt: Date | null;
    sundayStart: number | null;
    mondayStart: number | null;
    tuesdayStart: number | null;
    wednesdayStart: number | null;
    thursdayStart: number | null;
    fridayStart: number | null;
    saturdayStart: number | null;
}

export interface HipcomBulkUpdateCloseSoftware extends BaseDTOIdLong {
    name: string | null;
    path: string | null;
    executableName: string | null;
    service: boolean | null;
}

export interface HipcomBulkUpdateCurrentSoftwareVersion extends BaseDTOIdLong {
    version: string | null;
    status: StatusType | null;
    checkedAt: Date | null;
    hipcomBulkUpdateDetailId: number | null;
    hipcomBulkUpdateLatestSoftwareVersionId: number | null;
    hipcomBulkUpdatePathId: number | null;
}

export interface HipcomBulkUpdateDetail extends BaseDTOIdLong {
    desktopServerId: number | null;
    hipcomBulkUpdateCurrentSoftwareVersionIds: number[] | null;
    status: StatusType | null;
    startAt: Date | null;
    finishedAt: Date | null;
    sundayStartHours: number | null;
    sundayStartMinutes: number | null;
    mondayStartHours: number | null;
    mondayStartMinutes: number | null;
    tuesdayStartHours: number | null;
    tuesdayStartMinutes: number | null;
    wednesdayStartHours: number | null;
    wednesdayStartMinutes: number | null;
    thursdayStartHours: number | null;
    thursdayStartMinutes: number | null;
    fridayStartHours: number | null;
    fridayStartMinutes: number | null;
    saturdayStartHours: number | null;
    saturdayStartMinutes: number | null;
}

export interface HipcomBulkUpdateDetailLog {
    id: string | null;
    active: boolean | null;
    comment: string | null;
    desktopServerId: number | null;
    status: string | null;
    log: string | null;
    createdAt: Date | null;
}

export interface HipcomBulkUpdateLatestSoftwareVersion extends BaseDTOIdLong {
    name: string | null;
    folderName: string | null;
    executableName: string | null;
    link: string | null;
    version: string | null;
    status: StatusType | null;
    startAt: Date | null;
    checkedAt: Date | null;
    finishedAt: Date | null;
}

export interface HipcomBulkUpdateMessage extends BaseDTOIdLong {
    message: string | null;
    timeInMinutes: number | null;
}

export interface HipcomBulkUpdatePath extends BaseDTOIdLong {
    path: string | null;
}

export interface PdfPrint extends BaseDTOIdLong {
    data: any | null;
    printId: number | null;
    jobId: number | null;
    jobTitle: string | null;
    user: string | null;
    clientId: string | null;
    createdAt: Date | null;
    fileUUID: string | null;
}

export interface PdfPrintEvent {
    id: number | null;
    sessionCookie: string | null;
}

export interface PluginMessage extends BaseDTOIdLong {
    serverId: number | null;
    serverType: ServerType | null;
    topic: string | null;
    message: string | null;
}

export interface Project extends BaseDTOIdLong {
    name: string | null;
    hasSubProjects: boolean | null;
}

export interface ProjectSharedInfo {
    projectName: string | null;
    subProjectCount: number;
    adUserCount: number;
    adUserCountUnique: number;
    adUserPoolCount: number;
    adUserPoolCountUnique: number;
}

export interface ScriptExecution extends BaseDTOIdLong {
    script: string | null;
    scriptExecutionType: ScriptExecutionType | null;
    desktopServerId: number | null;
    linuxServerId: number | null;
    pending: boolean;
    managerUserId: number | null;
    numbersOfErrors: number | null;
    maximumNumberOfAttempts: number | null;
    errorDescription: string | null;
    output: string | null;
    debug: string | null;
}

export interface SearchCriteria {
    field: string | null;
    value: string | null;
    fieldOrder: string | null;
    order: string | null;
    limit: number | null;
    page: number | null;
}

export interface ServicesStatus {
    id: number;
    comment: string;
    active: boolean;
    modified: number;
    optlock: number;
    api: string;
    terminal: string;
    monitoring: string;
    customLayout: string;
    gatewayManager: string;
    ipsManager: string;
    imageCache: string;
    others: string[];
    hasError: boolean;
}

export interface SessionNumbers {
    stompSubscription: number;
    stompSession: number;
    stompUser: number;
    session: number;
    sessionAttribute: number;
}

export interface R2cloudStompSession {
    sessionId: string | null;
    managerUserId: number | null;
    desktopServerId: number | null;
    linuxServerId: number | null;
    clientVersion: string | null;
    webClientId: string | null;
    ip: string | null;
    userAgent: string | null;
    connectAt: string | null;
    disconnectAt: string | null;
    stompClientType: string | null;
    clientId: string | null;
    stompUsername: string | null;
}

export interface TextFile extends BaseDTOIdLong {
    text: string | null;
}

export interface UploadFile extends BaseDTOIdLong {
    name: string | null;
    data: any | null;
    managerUserId: number | null;
    desktopServerId: number | null;
    adUserId: number | null;
    adUserPoolId: number | null;
    adUserLogin: string | null;
    size: number | null;
    createdAt: Date | null;
    fileUUID: string | null;
    changing: boolean;
    deleting: boolean;
    updatedAt: Date | null;
}

export interface Page {
    index: number;
    size: number;
}

export interface TableCriteria {
    fields: TableFieldSearch[];
    page: Page;
    order: TableOrder | null;
}

export interface TableFieldSearch {
    field: string;
    value: string;
    fieldType: TableFieldType;
    searchType: TableFieldSearchType;
    criteria: TableFieldSearchCriteria;
}

export interface TableOrder {
    field: string;
    order: string;
}

export interface TableResults<T> {
    content: T[];
    total: number;
}

export interface BooleanValue {
    value: boolean | null;
}

export interface SlimPermission {
    name: string;
    customerGroupIds: number[];
    subProjectIds: number[];
}

export interface Permission extends BaseDTOIdLong {
    name: string | null;
    customerGroupIds: number[] | null;
    subProjectIds: number[] | null;
}

export interface Role extends BaseDTOIdLong {
    name: string | null;
    permissionIds: number[] | null;
}

export interface RoleIdCustomerGroupIdManagerUserId extends BaseIAMId {
    customerGroupId: number | null;
    managerUserId: number | null;
}

export interface RoleIdSubProjectIdManagerUserId extends BaseIAMId {
    subProjectId: number | null;
    managerUserId: number | null;
}

export interface RoleSubProjectManagerUseInBatch {
    emails: string[];
    roleId: number;
    subProjectId: number;
}

export interface Image {
    data: any;
}

export interface ClientStatus {
    status: R2CloudClientStatus;
    clientId: string | null;
    webClientId: string | null;
}

export interface ClientUpdate {
    clientId: string | null;
}

export interface LinuxServerStatus {
    linuxServerId: number;
    status: ServerStatusType;
    clientVersion: string;
}

export interface RemoteAppReady {
    status: RemoteAppReadyStatus;
    serviceApp: boolean;
    remoteAppId: number;
    customerGroupId: number;
    subProjectId: number;
    name: string;
    imageUUID: string | null;
}

export interface ScriptExecutionFinishedEvent {
    scriptExecutionId: number;
    output: string | null;
    error: string | null;
    debug: string | null;
    scriptExecutionType: ScriptExecutionType | null;
}

export interface ServerConnected {
    desktopServerId: number;
}

export interface ServerDisconnected {
    desktopServerId: number;
}

export interface ServerStatus {
    desktopServerId: number;
    status: ServerStatusType;
    clientVersion: string;
    username: string;
}

export interface GrantedAuthority {
    authority: string;
}

export interface BaseAccessLog {
    id: string | null;
    active: boolean | null;
    comment: string | null;
    actionType: ActionType | null;
    managerUserId: number | null;
    managerUserPicture: string | null;
    managerUserEmail: string | null;
    managerUserIp: string | null;
    managerUserCustomerGroupId: number | null;
    managerUserCustomerGroupName: string | null;
    clientVersion: string | null;
    webClientId: string | null;
    userAgent: string | null;
    adUserDomain: string | null;
    adUserLogin: string | null;
    adUserPool: boolean | null;
    customerGroupId: number | null;
    subProjectId: number | null;
    subProjectName: string | null;
    serverId: number | null;
    serverType: ServerType | null;
    serverIp: string | null;
    serverFQDN: string | null;
    serverHostname: string | null;
    createdAt: Date | null;
}

export interface BaseDTOIdLong extends IBaseDTOIdLong {
}

export interface IDotNetServerPlugin extends IdLong {
    name: string | null;
}

export interface BaseIAMId {
    roleId: number | null;
}

export interface IBaseDTOIdLong extends IdLong {
    modified: number | null;
    optlock: number | null;
    active: boolean | null;
    comment: string | null;
}

export interface IdLong {
    id: number | null;
}

export enum SnapshotType {
    ALL = 'ALL',
    INITIAL = 'INITIAL',
    UPDATE = 'UPDATE',
    TERMINAL = 'TERMINAL',
}

export enum TableFieldSearchCriteria {
    MUST = 'MUST',
    FILTER = 'FILTER',
    SHOULD = 'SHOULD',
    MUST_NOT = 'MUST_NOT',
}

export enum TableFieldSearchType {
    WILDCARD = 'WILDCARD',
    TERM = 'TERM',
    MATCH = 'MATCH',
}

export enum TableFieldType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
}

export enum RemoteAppReadyStatus {
    READY = 'READY',
    CLIENT_NOT_CONNECTED = 'CLIENT_NOT_CONNECTED',
    SERVER_NOT_CONNECTED = 'SERVER_NOT_CONNECTED',
    PREPARING = 'PREPARING',
    DELETING = 'DELETING',
}

export enum ManagerUserProvider {
    GOOGLE = 'GOOGLE',
    EMAIL_PASSWORD = 'EMAIL_PASSWORD',
}

export enum ActionType {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    SHUTDOWN = 'SHUTDOWN',
}

export enum ServerType {
    WINDOWS = 'WINDOWS',
    LINUX = 'LINUX',
}

export enum ServerConnectionType {
    PUBLIC_IP = 'PUBLIC_IP',
    PRIVATE_IP = 'PRIVATE_IP',
    CONNECTION_FQDN = 'CONNECTION_FQDN',
}

export enum ClientVersionExpression {
    GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
    LESS_THAN = 'LESS_THAN',
    EQUAL = 'EQUAL',
    NOT = 'NOT',
    ANY = 'ANY',
}

export enum StompClientType {
    WEB = 'WEB',
    CLIENT = 'CLIENT',
    SERVER = 'SERVER',
    LINUX_SERVER = 'LINUX_SERVER',
}

export enum StatusType {
    WAITING = 'WAITING',
    UPDATING = 'UPDATING',
    STARTED = 'STARTED',
    ERROR = 'ERROR',
    OK = 'OK',
}

export enum ScriptExecutionType {
    USER_LOGOFF = 'USER_LOGOFF',
    SERVER_RESTART = 'SERVER_RESTART',
    PRINT = 'PRINT',
    DISCONNECT_ALL_USERS_FROM_SERVER = 'DISCONNECT_ALL_USERS_FROM_SERVER',
    UPDATE_LEGACY = 'UPDATE_LEGACY',
    UPDATE = 'UPDATE',
    PROCESS_KILL = 'PROCESS_KILL',
    SERVICE_START = 'SERVICE_START',
    SERVICE_STOP = 'SERVICE_STOP',
    SERVICE_RESTART = 'SERVICE_RESTART',
    ENABLE_ACCOUNT = 'ENABLE_ACCOUNT',
    UNLOCK_ACCOUNT = 'UNLOCK_ACCOUNT',
    GENERIC_INFO = 'GENERIC_INFO',
}

export enum R2CloudClientStatus {
    CONNECTED = 'CONNECTED',
    CLIENT_UPDATE = 'CLIENT_UPDATE',
    NOT_CONNECTED = 'NOT_CONNECTED',
    INSTALLED = 'INSTALLED',
    NOT_INSTALLED = 'NOT_INSTALLED',
    STOMP_NOT_CONNECTED = 'STOMP_NOT_CONNECTED',
    OPENING = 'OPENING',
}

export enum ServerStatusType {
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    STOMP_DISCONNECT = 'STOMP_DISCONNECT',
}
