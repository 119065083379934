<form *ngIf="myForm" [formGroup]="myForm"
      id="ad-domain-create-form"
      (submit)="(myForm.valid && myForm.dirty && onSubmit()) || (adDomainFilterControl.value && onSubmit()) ">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Cadastro de Domínio</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div *ngIf="showADDomainForm === ''">


        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent" (click)="onSelectShowADDomain('n')"
                    aria-label="Salvar">Selecionar um domínio existente
            </button>
            <button type="button" mat-raised-button color="primary" (click)="onSelectShowADDomain('y')">Criar um novo
                domínio
            </button>
        </div>


    </div>

    <div *ngIf="showADDomainForm !== ''">

        <div *ngIf="showADDomainForm === 'n'" class="mat-white-bg mat-elevation-z2" fxLayout="column"
             fxLayoutAlign="space-between stretch" matDialogContent>
            <mat-form-field *ngIf="adDomains">
                <mat-select placeholder="Domínios existentes" [formControl]="adDomainFilterControl">
                    <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain">
                        {{adDomain.domainName}} - {{adDomain.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="showADDomainForm === 'y'" class="mat-white-bg mat-elevation-z2" fxLayout="column"
             fxLayoutAlign="space-between stretch" matDialogContent>
            <mat-form-field>
                <input matInput formControlName="name" placeholder="Nome (identificador)">
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input matInput formControlName="domainName" placeholder="Domínio do Active Directory">
                <mat-error>
                    {{myForm.get('domainName').errors | error}}
                </mat-error>
            </mat-form-field>


            <div style="width: 480px; padding: 10px 10px 10px 10px;margin-top: 20px;margin-bottom: 35px;border-left: none;border-right: none; ">
                <mat-form-field *ngIf="myForm.get('id').value && desktopServers">
                    <mat-select style="width: 460px;" placeholder="Servidor Controlador de Domínio" formControlName="adServerId">
                        <mat-option *ngFor="let desktopServer of desktopServers" [value]="desktopServer.id">
                            {{desktopServer.hostname}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="adDomain.adServerId">
                    <button type="button" mat-raised-button color="accent"
                            (click)="onEditAdServer(adDomain.adServerId)">Editar o servidor do
                        domínio {{adDomain.adServerId}}</button>
                </div>
            </div>
            <!--<div *ngIf="project"-->
            <!--style="margin-top: 10px; padding-bottom: 15px; margin-bottom: 10px;border-bottom: solid 1px #dddd;">-->
            <!--<div>-->
            <!--<b>Projeto:</b>-->
            <!--</div>-->
            <!--<div>{{project.name}}</div>-->
            <!--</div>-->

            <div style="padding: 5px 15px 15px 15px;">
                <mat-slide-toggle formControlName="active" name="active">
                    Ativo
                </mat-slide-toggle>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="(!myForm.valid || myForm.pristine) && !adDomainFilterControl.value"
                    aria-label="Salvar">
                <span *ngIf="adDomain?.id; else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <button type="button" mat-raised-button color="warn"
                    id="ad-domain-create-delete"
                    *appIsAdmin (click)="onRemove()">Excluir</button>
            <button type="button" mat-raised-button color="warn"
                    id="ad-domain-create-remove-from-project"
                    *appIsAdmin (click)="onRemoveFromProject()">Remover do projeto</button>
            <button type="button"
                    id="ad-domain-create-cancel"
                    mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

            <div *ngIf="adDomain?.id">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px">
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: adDomain; type: 'ADDomain'"
                            (click)="onJaversHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: adDomain; type: 'ADDomain'"
                            (click)="onJaversAllHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico completo</span>
                    </button>
                </mat-menu>
            </div>



        </div>
    </div>
</form>
