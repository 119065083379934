import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as download from 'download.js';
import {Observable, of} from 'rxjs';
import {NginxFile} from '../../helpers/nginx-file';
import {map, shareReplay} from 'rxjs/operators';
import {DomainPathService} from '../../domain-path/domain-path.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {CustomerGroupTemplateDaoService} from '../custom-layout/customer-group-template/customer-group-template-dao.service';

@Injectable()
export class ClientDownloadService {

    private static url;
    private static urlServer;
    private static urlLinuxServer;
    private downloadUrl$: Observable<string>;
    private filename: string;

    private static getVersionAsNumber(nginxFile: NginxFile): number {
        const name = nginxFile.name.replace('.exe', '');
        const index = name.lastIndexOf('-');
        const versionString = name.substr(index + 1, name.length);
        const strings = versionString.split('.');
        for (let i = 0; i < strings.length; i++) {
            strings[i] = ClientDownloadService.pad(strings[i], 3);
        }
        const versionNumberString = strings.join('');
        return parseInt(versionNumberString, 10);
    }

    private static pad(num: string, size: number): string {
        let result = num;
        while (result.length < size) {
            result = '0' + result;
        }
        return result;
    }

    constructor(
        private http: HttpClient,
        private domainPathService: DomainPathService,
        private userAuthorityDao: UserAuthorityDaoService,
        private customerGroupDaoService: CustomerGroupDaoService,
        private customerGroupTemplateDaoService: CustomerGroupTemplateDaoService,
    ) {
        ClientDownloadService.url = domainPathService.staticURL + 'client/';
        ClientDownloadService.urlServer = domainPathService.staticURL + 'server/';
        ClientDownloadService.urlLinuxServer = domainPathService.staticURL + 'linux_server/';
    }

    private downloadExternalAgentInstallerFromCustomLayoutIfExists(): void {
        this.userAuthorityDao.getMe().subscribe(user => {
            if (user) {
                this.customerGroupDaoService.getByAuthenticatedManagerUser().subscribe((customerGroup) => {
                    if (customerGroup) {
                        this.customerGroupTemplateDaoService.findOneByManagerUserId(user.managerUser.id).subscribe((customerGroupTemplate) => {
                                if (customerGroupTemplate) {
                                    this.customerGroupTemplateDaoService.setCurrentCustomerGroupTemplate(customerGroupTemplate);
                                    const customPortalTemplate = customerGroupTemplate?.customPortalTemplate;
                                    if (customPortalTemplate?.hasCustomProtocol && customPortalTemplate?.installUrl) {
                                        this.downloadByUrl(customPortalTemplate?.installUrl);
                                    }
                                }
                            }
                        );
                    }
                });
            }
        });
    }

    public download(): void {
        if (this.downloadUrl$ == null) {
            this.downloadUrl$ = this.http.get<NginxFile[]>(ClientDownloadService.url).pipe(map((response) => {
                const sorted = response.sort((o1, o2) => {
                    return ClientDownloadService.getVersionAsNumber(o1) - ClientDownloadService.getVersionAsNumber(o2);
                }).reverse();
                return ClientDownloadService.url + sorted[0].name;
            }), shareReplay(1));
        }
        this.downloadUrl$.subscribe((url) => {
            const index = url.lastIndexOf('/');
            const filename = url.substr(index + 1, url.length);
            download.downloadUrl(filename, url);
        });
        this.downloadExternalAgentInstallerFromCustomLayoutIfExists();
    }

    downloadByUrl(url: string): void {
        const index = url.lastIndexOf('/');
        const filename = url.substr(index + 1, url.length);
        download.downloadUrl(filename, url);
    }

    public getLinuxServerClientUrl(): Observable<string> {
        return of(ClientDownloadService.urlLinuxServer + 'r2cloud-linux-agent-install.sh');
    }

    public getServerClientUrl(): Observable<string> {
        if (this.downloadUrl$ == null) {
            this.downloadUrl$ = this.http.get<NginxFile[]>(ClientDownloadService.urlServer).pipe(map((response) => {
                const sorted = response.sort((o1, o2) => {
                    return ClientDownloadService.getVersionAsNumber(o1) - ClientDownloadService.getVersionAsNumber(o2);
                }).reverse();
                return ClientDownloadService.urlServer + sorted[0].name;
            }), shareReplay(1));
        }
        return this.downloadUrl$;

        // this.downloadUrl$.subscribe((url) => {
        //   const index = url.lastIndexOf('/');
        //   const filename = url.substr(index + 1, url.length);
        //   //download.downloadUrl(filename, url);
        //   // console.debug(ClientDownloadService.urlServer + this.filename);
        //   // return ClientDownloadService.urlServer + this.filename;
        // });

    }


}
