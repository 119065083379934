<form *ngIf="customerGroupForm" [formGroup]="customerGroupForm" (ngSubmit)="customerGroupForm.valid && onSubmit()"
      id="customer-group-create-edit-form">

    <div class="page-layout carded fullwidth">
        <div class="top-bg accent"></div>
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
             style="z-index: 99;margin-top: 5px;height: 70px">
            <div fxLayout="row" fxLayoutAlign="start center" style="min-height: 92px;">
                <mat-icon class="mr-10 font-size-24">edit</mat-icon>
                <span class="h1 m-0">Cadastro de Empresa </span>
            </div>
        </div>

        <div class="mat-white-bg mat-elevation-z2 " fxLayout="column" fxLayoutAlign="space-between stretch"
             matDialogContent style="padding-left: 0;padding-right: 0;">

            <mat-tab-group animationDuration="0ms" disableRipple="true" id="customer-group-tab-category">
                <mat-tab label="Cadastro" id="customer-group-form">
                    <table>
                        <tr>
                            <td class="vertical-align-top w-150">
                                <div>
                                    <img
                                        src="{{imageFileService.getCustomerGroupPictureUrl(customerGroup.imageUUID, 150)}}"
                                        width="150"
                                        height="150"
                                        alt=""
                                        style="max-height: 150px; max-width: 150px;"
                                        *ngIf="!imageFileElement">
                                    <img [src]="'data:image/jpg;base64,' + imageFileElement" width="150" height="150" alt="" style="max-height: 150px; max-width: 150px;"
                                         *ngIf="imageFileElement">
                                </div>
                                <button style="width: 150px;margin-top: 5px;" type="button" mat-raised-button
                                        class="green-800-bg green-800"
                                        onclick="document.getElementById('fileToUpload').style.display = 'block'; document.getElementById('fileToUpload').click()">
                                    Alterar Foto
                                </button>
                                <button *ngIf="imageFileElement || customerGroup.imageUUID" style="width: 150px;margin-top: 5px;" type="button" mat-raised-button
                                        class="red-800-bg red-800"
                                        (click)="onRemoveImage()">
                                    Remover Foto
                                </button>
                                <input (change)="onImageFileChange($event)" name="file"
                                       id="fileToUpload" accept="image/*" type="file"
                                       style="width: 100px;overflow: hidden;display: none">
                            </td>
                            <td class="vertical-align-top pl-20 w-680">
                                <mat-form-field class="full-width p-10" appearance="outline">
                                    <mat-label>Nome da empresa</mat-label>
                                    <input matInput placeholder="Nome da empresa" name="name" required
                                           formControlName="name" id="customer-group-create-edit-name">
                                    <mat-error>
                                        {{customerGroupForm.get('name').errors | error}}
                                    </mat-error>
                                </mat-form-field>

                                <div class="border p-10 mb-20">
                                    <div
                                        style="color:#ff0000;padding-bottom: 10px;padding-left: 10px;padding-top: 10px;"
                                        *ngIf="customerGroupForm.get('contractedUsers').value === 0">
                                        * 0 simultâneos ninguem da empresa poderá acessar
                                    </div>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Número de usuários simultâneos contratados</mat-label>
                                        <input matInput type="number" min="0"
                                               placeholder="Número de usuários simultâneos contratados"
                                               id="customer-group-create-edit-contracted-users"
                                               name="contractedUsers" required formControlName="contractedUsers">
                                        <mat-error>
                                            {{customerGroupForm.get('contractedUsers').errors | error}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Mensagem customizada de máx. usuários simultâneos atingido
                                        </mat-label>
                                        <input matInput
                                               placeholder="Mensagem customizada de máx. usuários simultâneos atingido."
                                               name="exceededMaximumUsersMessage"
                                               formControlName="exceededMaximumUsersMessage">
                                    </mat-form-field>
                                </div>
                                <div class="border p-10 mb-20">
                                    <mat-slide-toggle formControlName="blockAllAccess" name="blockAllAccess"
                                                      style="margin-bottom: 15px;margin-top: 8px;">
                                        Bloquear todos os acessos
                                    </mat-slide-toggle>

                                    <mat-form-field class="full-width" appearance="outline">
                                        <mat-label>Mensagem customizada de bloqueio dos acessos
                                        </mat-label>
                                        <input matInput
                                               placeholder="Mensagem customizada de bloqueio dos acessos."
                                               name="blockAllAccessMessage"
                                               formControlName="blockAllAccessMessage">
                                    </mat-form-field>
                                </div>

                                <div *ngIf="customerGroup.id && customerGroup.url" class="pl-10 mb-20">
                                    <div>
                                        <div class="font-size-10 primary-300-fg font-weight-500">URL:</div>
                                        <div>{{'https://' + getCurrentDomain() + '/login?url=' + customerGroup.url}}</div>
                                    </div>
                                </div>

                                <div class="full-width p-10" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>
                                        <mat-form-field class="full-width min-width-450" appearance="outline">
                                            <mat-label>Tema do portal</mat-label>
                                            <mat-select class="full-width" placeholder="Tema do portal"
                                                        formControlName="customPortalTemplateId">
                                                <mat-option
                                                    *ngFor="let customPortalTemplate of customPortalTemplateList; let i = index;"
                                                    [value]="customPortalTemplate.id">
                                                    {{customPortalTemplate.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="full-width p-10" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div>
                                        <mat-form-field class="full-width min-width-450" appearance="outline">
                                            <mat-label>Tema de imagens de fundo</mat-label>
                                            <mat-select class="full-width" placeholder="Tema de imagens de fundo"
                                                        formControlName="backgroundImageTemplateId">
                                                <mat-option
                                                    *ngFor="let backgroundImageTemplate of backgroundImageTemplateList; let i = index;"
                                                    [value]="backgroundImageTemplate.id">
                                                    {{backgroundImageTemplate.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div *ngIf="isAdmin" class="full-width p-10" fxLayout="row"
                                     fxLayoutAlign="space-between center">
                                    <div>
                                        <mat-form-field class="full-width min-width-450" appearance="outline">
                                            <mat-label>Parceiros</mat-label>
                                            <mat-select class="full-width" placeholder="Parceiros"
                                                        formControlName="partnerCustomerGroupId">
                                                <mat-option
                                                    *ngFor="let partnerCustomerGroup of customerGroupService.customerGroups; let i = index;"
                                                    [value]="partnerCustomerGroup.id">
                                                    {{partnerCustomerGroup.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="border p-10">
                                    <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                                        <div>
                                            <mat-form-field class="full-width min-width-450" appearance="outline">
                                                <mat-label>Fornecedor de Software que utiliza</mat-label>
                                                <mat-select class="full-width"
                                                            placeholder="Fornecedor de Software que utiliza"
                                                            formControlName="softwareCompanyIds"
                                                            [multiple]="true" #multiSelect
                                                            id="customer-group-create-edit-select-software-company-ids">
                                                    <mat-option>
                                                        <ngx-mat-select-search
                                                            [formControl]="softwareCompanyMultiFilterCtrl"
                                                            placeholderLabel="busca"></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option
                                                        *ngFor="let softwareCompany of filteredSoftwareCompanyMulti | async; let i = index;"
                                                        id="customer-group-create-edit-select-software-company-id-{{i}}"
                                                        [value]="softwareCompany.id">
                                                        {{softwareCompany.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="mb-20">
                                            <button class="ml-15" type="button" mat-raised-button color="accent"
                                                    id="customer-group-create-software-company"
                                                    (click)="onCreateSoftwareCompany()">Criar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="mb-60 full-width"
                                         *ngIf="customerGroupForm.get('softwareCompanyIds').value > 0">

                                        <table class="row-odd full-width" cellspacing="0" cellpadding="0">
                                            <tr *ngFor="let softwareCompanyId of customerGroupForm.get('softwareCompanyIds').value">
                                                <div
                                                    *ngIf="softwareCompanyService.findById(+softwareCompanyId, softwareCompanyList) as softwareCompany">
                                                    <td style="width: 85px;">
                                                        <button type="button" mat-button color="accent"
                                                                *ngIf="softwareCompany"
                                                                (click)="onEditSoftwareCompany(softwareCompany)">
                                                            Editar
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="softwareCompany">
                                                            {{softwareCompany.name}}
                                                        </div>
                                                    </td>
                                                </div>
                                            </tr>
                                        </table>


                                    </div>
                                </div>

                                <div fxLayout="row" fxLayoutAlign="space-between" fxFill class="p-16" matDialogActions>
                                    <mat-form-field class="full-width min-width-230">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>Observações</mat-label>
                                            <textarea rows="15" matInput formControlName="comment"
                                                      class="full-width"></textarea>
                                        </mat-form-field>
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayoutAlign="space-between" fxFill class="p-16 mb-40"
                                     matDialogActions>
                                    <mat-slide-toggle formControlName="localAdminUsersOnly" name="localAdminUsersOnly">
                                        Somente usuários de administração local
                                    </mat-slide-toggle>
                                </div>

                                <div fxLayout="row" fxLayoutAlign="space-between" fxFill class="p-16 mb-40"
                                     matDialogActions>
                                    <mat-slide-toggle formControlName="active" name="active">
                                        Ativo
                                    </mat-slide-toggle>
                                </div>


                            </td>
                        </tr>
                    </table>
                </mat-tab>

                <mat-tab label="Html login" id="customer-group-html" class="full-width">
                    <div style="padding-right: 12px;">
                        <mat-form-field class="full-width min-width-450">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>HTML da página de login</mat-label>
                                <textarea rows="25" matInput [formControl]="htmlCtrl" class="full-width"></textarea>
                            </mat-form-field>
                        </mat-form-field>
                    </div>
                </mat-tab>
            </mat-tab-group>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="grey-50-bg border-top"
             style="padding-right: 20px; padding-left: 20px"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent" id="customer-group-create-edit-submit"
                    [disabled]="!customerGroupForm.valid || customerGroupForm.pristine" aria-label="Salvar">
                <span *ngIf="this.customerGroupForm.get('id').value;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <div *ngIf="this.customerGroupForm.get('id').value">
                <button type="button" mat-raised-button color="warn" (click)="onRemove()"
                        id="customer-group-create-remove"
                >Excluir
                </button>
            </div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()"
                    id="customer-group-create-cancelar">Cancelar
            </button>


            <div *ngIf="customerGroup?.id">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="open menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" style="max-width: 372px" >
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: customerGroup; type: 'CustomerGroup'"
                            (click)="onJaversHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico</span>
                    </button>
                    <button mat-menu-item *appIfPermission="'AUDIT'; data: customerGroup; type: 'CustomerGroup'"
                            (click)="onJaversAllHistory()">
                        <mat-icon>history</mat-icon>
                        <span>Histórico completo</span>
                    </button>
                </mat-menu>
            </div>
        </div>

    </div>


</form>

