import {Component, OnInit} from '@angular/core';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {DesktopServerPoolDaoService} from '../../desktop-server-pool/desktop-server-pool-dao.service';
import {DesktopServer, DesktopServerPool, FirebaseUserDetails, ServerStatus, ServerStatusType} from '../../../models';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {faCircle, faCopy, faRobot} from '@fortawesome/free-solid-svg-icons';
import {DesktopServerStatusService} from '../../../ws/desktop-server-status.service';
import {CrudOperationWrapper, formatUUID} from '../../../helpers/kluh';
import {MatDialog} from '@angular/material/dialog';
import {DesktopServerPoolEditComponent} from '../../desktop-server-pool/desktop-server-pool-edit/desktop-server-pool-edit.component';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {DesktopServerCreateComponent} from '../../desktop-server/desktop-server-create/desktop-server-create.component';
import {filter, take} from 'rxjs/operators';
import {DesktopServerPoolChoiceComponent} from '../../desktop-server-pool/desktop-server-pool-choice/desktop-server-pool-choice.component';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ManagerUserPermissionsDaoService} from '../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {OpenModalAdAdminComponent} from '../../ad-admin/open-modal-ad-admin/open-modal-ad-admin.component';
import {R2CloudStompService} from '../../../ws/r2-cloud-stomp.service';

@Component({
    selector: 'app-r2-cloud-admin-desktop-server-pool',
    templateUrl: './r2-cloud-admin-desktop-server-pool.component.html',
    styleUrls: ['./r2-cloud-admin-desktop-server-pool.component.scss']
})
export class R2CloudAdminDesktopServerPoolComponent extends ComponentCleaner implements OnInit {


    canReadList: PermissionWrapper[];
    canCreateList: PermissionWrapper[];
    canEditList: PermissionWrapper[];
    canEditServerList: PermissionWrapper[];
    desktopServerPoolList: DesktopServerPool[] = [];
    desktopServerList: DesktopServer[] = [];
    serverStatusList: ServerStatus[] = [];

    protected readonly faRobot = faRobot;
    protected readonly faCopy = faCopy;
    protected readonly formatUUID = formatUUID;

    public user: FirebaseUserDetails;
    public isAdmin = false;

    protected readonly faCircle = faCircle;

    constructor(
        private dialog: MatDialog,
        private desktopServerPoolDaoService: DesktopServerPoolDaoService,
        private customerGroupService: CustomerGroupService,
        private desktopServerStatusService: DesktopServerStatusService,
        private desktopServerDaoService: DesktopServerDaoService,
        private userAuthorityDao: UserAuthorityDaoService,
        private stomp: R2CloudStompService,
        private managerUserPermissionsDao: ManagerUserPermissionsDaoService,
        public adminService: R2CloudAdminService) {
        super();

        this.canReadList = [
            {type: 'DesktopServerPool', permission: 'READ', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'DesktopServerPool', permission: 'READ', parentType: 'SubProject', parent: this.adminService.subProject}
        ];
        this.canCreateList = [
            {type: 'DesktopServerPool', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'DesktopServerPool', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject}
        ];
        this.canEditList = [
            {type: 'DesktopServerPool', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'DesktopServerPool', permission: 'WRITE', parentType: 'SubProject', parent: this.adminService.subProject}
        ];
        this.canEditServerList = [
            {type: 'DesktopServer', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'DesktopServer', permission: 'WRITE', parentType: 'SubProject', parent: this.adminService.subProject},
            {type: 'LinuxServer', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'LinuxServer', permission: 'WRITE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];


        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
        });
        this.managerUserPermissionsDao.getMyUserPermissions().pipe(take(1)).subscribe((result) => {
            if (result.admin) {
                this.isAdmin = true;
            }
        });

    }


    onOpenModalADAdmin(desktopServer: DesktopServer): void {
        this.dialog.open(OpenModalAdAdminComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                desktopServer: desktopServer
            }
        });
    }

    openDesktopServer(desktopServer: DesktopServer): void {
        desktopServer.comment = 'LOADING';
        this.stomp.send('/stomp/open-local-admin', {id: desktopServer.id});
        setTimeout(() => {
            desktopServer.comment = '';
        }, 5000);

    }

    ngOnInit(): void {
        this.addSubscription(this.adminService.desktopServersOfPool$.subscribe(desktopServers => {
            if (desktopServers.length > 0) {
                this.loadInitData();
            }
        }));

        this.addSubscription(this.adminService.desktopServerPools$.subscribe(() => {
            this.loadInitData();
        }));
    }

    private loadInitData(): void {
        this.desktopServerList = [];
        this.desktopServerPoolList = this.adminService.desktopServerPools;
        this.populateDesktopServerList(this.desktopServerPoolList);
    }

    private reLoadInitData(): void {
        this.desktopServerPoolDaoService.filter({subProjectId: this.adminService.subProject.id}).subscribe((desktopServerPoolList: DesktopServerPool[]) => {
            this.adminService.desktopServerPools = desktopServerPoolList;
            const desktopServerPoolIds = desktopServerPoolList.map(d => d.id);
            this.desktopServerDaoService.findAllByDesktopServerPoolIds(desktopServerPoolIds).subscribe((desktopServerList: DesktopServer[]) => {
                this.adminService.desktopServersOfPool = desktopServerList;
                this.loadInitData();
            });
        });
    }

    private populateDesktopServerList(desktopServerPoolList: DesktopServerPool[]): void {
        desktopServerPoolList?.forEach(desktopServerPool => {
            const desktopServerList = this.adminService.getDesktopServersByDesktopServerPoolId(desktopServerPool.id);
            const sortDesktopServerList = this.sortDesktopServerList(desktopServerList, desktopServerPoolList.map(d => d.appBaseDesktopServerId));
            this.addDesktopServerOnDesktopServerList(sortDesktopServerList);
            this.statusServer(sortDesktopServerList);
            this.addDesktopServerOnListIfDontExists(desktopServerList, desktopServerPool.appBaseDesktopServerId);
        });
    }

    private addDesktopServerOnListIfDontExists(desktopServerList: DesktopServer[], appBaseDesktopSeverId: number): void {
        if (desktopServerList.length > 0) {
            const index = desktopServerList.findIndex(server => server.id === appBaseDesktopSeverId);
            if (index > -1) {
                this.adminService.addOrReplaceDesktopServer(desktopServerList[index]);
            }
        }
    }

    private addDesktopServerOnDesktopServerList(desktopServers: DesktopServer[]): void {
        desktopServers.forEach(desktopServer => {
            const exists = this.desktopServerList.some(desk => desk.id === desktopServer.id);
            if (!exists) {
                this.desktopServerList.push(desktopServer);
            }
        });
    }

    private statusServer(desktopServerList: DesktopServer[]): void {
        desktopServerList.forEach(desktopServer => {
            this.addSubscription(
                this.desktopServerStatusService.onDesktopServerStatus(desktopServer.id).subscribe((serverStatus) => {
                    this.changeStatusList(serverStatus);
                })
            );
        });
    }

    private changeStatusList(serverStatus: ServerStatus): void {
        const index = this.serverStatusList.findIndex(status => status.desktopServerId === serverStatus.desktopServerId);
        if (index > 0) {
            this.serverStatusList[index] = serverStatus;
        } else {
            this.serverStatusList.push(serverStatus);
        }
    }

    isDesktopServerConnected(desktopServerId: number): boolean {
        return this.getServerStatus(desktopServerId)?.status === ServerStatusType.CONNECTED;
    }

    getDesktopServerVersion(desktopServerId: number): string {
        return this.getServerStatus(desktopServerId)?.clientVersion;
    }

    private getServerStatus(desktopServerId: number): ServerStatus {
        return this.serverStatusList.find((serverStatus) => {
            return serverStatus.desktopServerId === desktopServerId;
        });
    }

    private sortDesktopServerList(desktopServerList: DesktopServer[], appBaseDesktopServerIds: number[]): DesktopServer[] {
        const itemsToMove = desktopServerList.filter(item => appBaseDesktopServerIds.includes(item.id));
        const desktopServerListInner = desktopServerList.filter(item => !appBaseDesktopServerIds.includes(item.id));
        itemsToMove.reverse().forEach(item => desktopServerListInner.unshift(item));
        return desktopServerListInner;
    }

    getAllDesktopServerByDesktopServerPoolId(desktopServerPoolId: number): DesktopServer[] {
        const desktopServers = this.desktopServerList.filter((desktopServer: DesktopServer) => {
            return desktopServer.desktopServerPoolId === desktopServerPoolId;
        });
        return desktopServers.filter((desktopServer: DesktopServer) => {
            return (!this.isOlderThan(desktopServer.lastLoginAt, 3) && desktopServer.disposable) || (!desktopServer.disposable) || this.isDesktopServerConnected(desktopServer.id);
        });
    }

    getAllDesktopServerByDesktopServerPoolIdDisposableAndOffline3Days(desktopServerPoolId: number): DesktopServer[] {
        const desktopServers = this.desktopServerList.filter((desktopServer: DesktopServer) => {
            return desktopServer.desktopServerPoolId === desktopServerPoolId;
        });
        return desktopServers.filter((desktopServer: DesktopServer) => {
            return desktopServer.disposable && this.isOlderThan(desktopServer.lastLoginAt, 3) && !this.isDesktopServerConnected(desktopServer.id);
        });
    }

    private isOlderThan(dateInput: Date | string, days: number): boolean {
        if (!dateInput) {
            return false;
        }
        const date = new Date(dateInput);
        const today = new Date();
        const threeDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - days);
        const inputDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return inputDate < threeDaysAgo;


    }

    openCreate(): void {
        this.openChoice();
    }

    openChoice(): void {
        const dialogRef = this.dialog.open(DesktopServerPoolChoiceComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large'
        });
        this.addSubscription(
            dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
                const resultData = result?.data;
                if (result?.operation === 'CREATE' && resultData === 'NEW_R2_SCALING') {
                    this.openEdit(null);
                } else if (result?.operation === 'SAVE') {
                    const resultDesktopServerPool: DesktopServerPool = resultData;
                    console.log('openChoice: Apenas fazer a referencia desse R2 Scaling no SubProjeto', resultDesktopServerPool);
                    if (resultDesktopServerPool?.id) {
                        this.desktopServerPoolList.push(resultDesktopServerPool);
                        this.reLoadInitData();
                    }
                }
            }));
    }

    openEdit(desktopServerPool: DesktopServerPool | null): void {
        const dialogRef = this.dialog.open(DesktopServerPoolEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                desktopServerPool: desktopServerPool,
                commonDesktopServers: this.getAllDesktopServerByDesktopServerPoolId(desktopServerPool?.id).filter(x => !x.disposable),
            }
        });
        this.addSubscription(
            dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
                const resultDesktopServerPool: DesktopServerPool = result?.data;
                const index = this.desktopServerPoolList.findIndex((y) => y.id === resultDesktopServerPool.id);
                if (result?.operation === 'SAVE' && resultDesktopServerPool) {
                    this.desktopServerPoolList[index] = resultDesktopServerPool;
                } else if (result?.operation === 'CREATE' && resultDesktopServerPool) {
                    this.desktopServerPoolList.push(resultDesktopServerPool);
                } else if (result?.operation === 'DELETE' && resultDesktopServerPool) {
                    this.desktopServerPoolList.splice(index, 1);
                }
                if (resultDesktopServerPool) {
                    this.reLoadInitData();
                }
            }));
    }

    getStringConnectionPort(port: number): string {
        if (!port) {
            return ':3389';
        }
        return ':' + port;
    }

    onEditDesktopServer(desktopServerInput: DesktopServer): void {
        const dialogRef = this.dialog.open(DesktopServerCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomains: this.adminService.adDomains,
                desktopServer: desktopServerInput,
                desktopServers: this.adminService.desktopServers,
                subProject: this.adminService.subProject,
                project: this.adminService.getProject(this.adminService.subProject.projectId)
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
            this.desktopServerCallback(result);
        });
        this.addSubscription(subscription);
    }

    private desktopServerCallback(result: CrudOperationWrapper[]): void {
        if (result && result.length === 2) {
            const desktopServerOperation = result[0];
            const subProjectOperation = result[1];
            const desktopServer: DesktopServer = desktopServerOperation.data;
            if (desktopServerOperation.operation === 'SAVE') {
                this.adminService.replaceDesktopServer(desktopServer);
                this.adminService.replaceDesktopServerOfPool(desktopServer);
            }
            if (subProjectOperation.operation === 'SAVE') {
                this.adminService.subProject = subProjectOperation.data;
            }
            if (subProjectOperation.operation === 'DELETE') {
                this.adminService.removeDesktopServer(desktopServer.id);
                this.adminService.removeDesktopServerOfPool(desktopServer.id);
                this.adminService.subProject = subProjectOperation.data;
            }
        }
    }

}
